import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {
  // typewriter setup
  typewriterText = [
    'Die sichere und unkomplizierte Vorschlagsplattform für Unternehmen.',
    'Mit unseren Apps schnell und zielorientiert Ideen erfassen.',
    'Auf unserem Web Portal von überall auf die Vorschläge Ihrer Mitarbeiter zugreifen.',
  ];
  typewriterDisplay = '';
  i = 0;

  constructor(
    public dialog: MatDialog,
    private router: Router,
  ) {
    if (
      location.hostname !== 'www.ideaboxen.com' &&
      location.hostname !== 'www.integrity-box.net' &&
      location.hostname !== 'management.ideaboxen.com' &&
      location.hostname !== 'management.integrity-box.net' &&
      location.hostname !== 'localhost'
    ) {
      this.navigateTo('/client');
    }

    if (
      location.hostname === 'management.ideaboxen.com' ||
      location.hostname === 'management.integrity-box.net'
    ) {
      this.navigateTo('/management');
    }
  }

  ngOnInit(): void {
    // call typewriter function
    this.typingCallback(this);
  }

  // navigation
  navigateTo(url: string) {
    this.router.navigateByUrl(url);
  }

  // typewriter callback functions setup
  typingCallback(that: this) {
    const totalLenght = that.typewriterText[that.i].length;
    const currentLenght = that.typewriterDisplay.length;
    if (currentLenght < totalLenght) {
      that.typewriterDisplay += that.typewriterText[that.i][currentLenght];
      setTimeout(that.typingCallback, 110, that);
    } else {
      setTimeout(() => {
        that.i++;
        if (that.i === that.typewriterText.length) {
          that.i = 0;
        }
        that.typewriterDisplay = '';
        setTimeout(that.typingCallback, 110, that);
      }, 1600);
    }
  }

  // contact dialog setup
  openContactDialog() {
    this.dialog.open(ContactDialogComponent);
  }
}

// setup dialog component
@Component({
  selector: 'app-dialog-remove-order-dialog',
  templateUrl: 'dialog-contact-dialog.html',
})
export class ContactDialogComponent {}
