import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { onAuthStateChanged } from 'firebase/auth';
import {
  onSnapshot,
  query,
  collection,
  orderBy,
  FirestoreError,
  addDoc,
  doc,
  updateDoc,
} from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { Case } from 'src/app/shared/models/case.model';
import { FirebaseAuthService } from 'src/app/shared/services/firebase-auth.service';
import { firestore, auth, storage, functions } from 'src/firebase';
import { CaseCulturePortalComponent } from '../case-culture-portal/case-culture-portal.component';
import { ChatMessage } from 'src/app/shared/models/chatMessage.model';
import { v4 as uuid } from 'uuid';
import { FirebaseError } from 'firebase/app';
import { httpsCallable } from 'firebase/functions';
import { CaseFile } from 'src/app/shared/models/caseFile.model';
import {
  formatCaseCreated,
  formatCaseFileUploaded,
  formatChatMessageCreatedAt,
} from 'src/app/shared/functions/dateFormat';
import { FirebaseTimestamp } from 'src/app/shared/models/firebaseTimestamp.model';

@Component({
  selector: 'app-case-culture-portal-case',
  templateUrl: './case-culture-portal-case.component.html',
  styleUrls: ['./case-culture-portal-case.component.scss'],
})
export class CaseCulturePortalCaseComponent implements OnInit {
  // case object
  case: Case;

  // name selection in form
  nameSelection = false;

  // chat message
  message = '';

  // chat messages
  chatMessages: ChatMessage[];

  // chat message validation
  messageMaxLength = 4096;
  chatErrorMessage = '';

  @ViewChildren('messages')
  messages: ElementRef[];
  @ViewChild('content')
  content: ElementRef;

  // loading
  isLoading = false;

  // keep chat messages with id
  i = 0;

  // active tab
  activeTab: number;

  // angular material table setup
  displayedColumns: string[] = ['uploaded', 'download'];
  dataSource = new MatTableDataSource();

  // case files array
  caseFiles = [];

  // files for case
  files: File[] = [];

  // file validation
  maxFileCount = 20;
  maxFileSize = 5000000;
  filesErrorMessage = '';
  filesSuccessMessage = '';

  // file datasource for table
  filesDataSource = new MatTableDataSource();
  displayedColumnsFilesTable: string[] = ['file_name', 'remove'];

  constructor(
    public caseCulturePortalComponent: CaseCulturePortalComponent,
    private firebaseAuth: FirebaseAuthService,
    private router: Router,
    private ngZone: NgZone,
    private translate: TranslateService,
  ) {}

  async getCase() {
    try {
      const getCaseByClient = httpsCallable<
        Record<string, never>,
        { case: Case; error?: FirebaseError }
      >(functions, 'getCaseByClient');

      const { data } = await getCaseByClient();

      if (!data.case) {
        // differentiate between errors (handle following errors: account with email exists)
        throw data.error;
      }

      this.case = data.case;
      const created = new Date(
        (this.case.created as FirebaseTimestamp)._seconds * 1000,
      );
      this.case.created = formatCaseCreated(
        created,
        this.translate.currentLang,
      );

      if (this.case.name) {
        this.nameSelection = true;
      }
    } catch (error) {
      console.error(error);
    }
  }

  getChatMessages(clientId: string, caseId: string) {
    this.chatMessages = [];
    onSnapshot(
      query(
        collection(
          firestore,
          'clients',
          clientId,
          'cases',
          caseId,
          'chat_messages',
        ),
        orderBy('created_at', 'asc'),
      ),
      (querySnapshot) => {
        querySnapshot.docChanges().forEach(async (change) => {
          if (change.type === 'added') {
            const data = change.doc.data();

            // format chat message
            const reatedAt = new Date(data.created_at.seconds * 1000);
            data.created_at = formatChatMessageCreatedAt(
              reatedAt,
              this.translate.currentLang,
            );

            const chatMessage = {
              id: change.doc.id,
              name: data.name,
              is_case_user: data.is_case_user,
              text: data.text,
              created_at: data.created_at,
              case_manager_name: data.case_manager_name,
              read: data.read,
            };

            // update chat messages by case manager to read (by case user)
            if (chatMessage.is_case_user === false && !chatMessage.read) {
              await updateDoc(
                doc(
                  firestore,
                  'clients',
                  clientId,
                  'cases',
                  caseId,
                  'chat_messages',
                  chatMessage.id,
                ),
                {
                  read: new Date(),
                },
              );
            }

            this.chatMessages.push(chatMessage);
          }
        });

        // sort messages by date
        this.chatMessages.sort((a, b) =>
          a.created_at > b.created_at
            ? 1
            : b.created_at > a.created_at
              ? -1
              : 0,
        );

        // update new case manager message read to false
        this.updateCaseManagerMessagesToRead();
      },
      (error: FirestoreError) => {
        console.error(error);
      },
    );
  }

  // get case files
  getCaseFiles(clientId: string, caseId: string) {
    // unsubscribe from case files snapshot
    const unsubscribeCaseFiles = onSnapshot(
      query(
        collection(
          firestore,
          'clients',
          clientId,
          'cases',
          caseId,
          'case_files',
        ),
        orderBy('uploaded', 'asc'),
      ),
      (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            const caseFile = change.doc.data();

            // format chat message
            const reatedAt = new Date(caseFile.uploaded.seconds * 1000);
            caseFile.uploaded = formatCaseFileUploaded(
              reatedAt,
              this.translate.currentLang,
            );

            // add file object to case files array
            this.caseFiles.push(caseFile);
          }

          // sort case files by date
          this.caseFiles.sort(
            (a: { uploaded: number }, b: { uploaded: number }) =>
              a.uploaded < b.uploaded ? 1 : b.uploaded < a.uploaded ? -1 : 0,
          );

          // update case files table data source
          this.dataSource = new MatTableDataSource(this.caseFiles);
        });
      },
      (error: FirestoreError) => {
        unsubscribeCaseFiles();
        console.error(error);
      },
    );
  }

  async extract(clientId: string) {
    // get case
    await this.getCase();

    // get chat messages
    this.getChatMessages(clientId, auth.currentUser.uid);

    // get case files
    this.getCaseFiles(clientId, auth.currentUser.uid);

    this.isLoading = false;
  }

  async ngOnInit() {
    this.isLoading = true;

    // wait until auth is initialized
    const user = await this.firebaseAuth.getCurrentUser();

    // check initially if user is logged in
    if (user !== null) {
      // get firebase auth
      const claims = (await auth.currentUser.getIdTokenResult()).claims;

      // redirect user to case if logged in
      if (claims['role'] !== 'case_user') {
        // user is not logged in => redirect to login
        this.router.navigate(['client', 'login']);
      } else {
        this.extract(claims['client'] as string);
      }
    } else {
      // user is not logged in
      this.router.navigate(['client', 'login']);
    }

    // check if user is still logged in (subscribe to auth state)
    onAuthStateChanged(auth, async (user) => {
      if (user !== null) {
        // get firebase auth
        const claims = (await auth!.currentUser!.getIdTokenResult()).claims;

        // redirect user to case if logged in
        if (claims['role'] !== 'case_user') {
          // user is not logged in => redirect to login
          this.ngZone.run(() => this.router.navigate(['client', 'login']));
        }
      } else {
        // user is not logged in
        this.ngZone.run(() => this.router.navigate(['client', 'login']));
      }
    });
  }

  // scroll to bottom inside chat
  scrollToBottom(): void {
    this.content.nativeElement.scrollTop =
      this.content.nativeElement.scrollHeight;
  }

  // send chat message
  async sendChatMessage(messageText: string) {
    try {
      // chat message
      await addDoc(
        collection(
          firestore,
          'clients',
          this.caseCulturePortalComponent.client.id,
          'cases',
          auth.currentUser.uid,
          'chat_messages',
        ),
        {
          is_case_user: true,
          created_at: new Date(),
          text: messageText,
        },
      );
      // clear message
      this.message = '';
    } catch (error) {
      console.error(error);
      // show error to user
      this.chatErrorMessage =
        'Fehler beim Senden der Nachricht. Bitte laden Sie die Seite neu und versuchen es erneut.';
    }
  }

  // validate chat message on submit
  async onChatMessageFormSubmit(message: string) {
    // activate loading
    this.isLoading = true;
    // remove whitespaces at beginning and end of string
    message = message.trim();
    // validate message
    if (message.length > this.messageMaxLength) {
      this.chatErrorMessage =
        'Die Nachricht darf nicht länger als 4096 Zeichen sein.';
      // deactivate loading
      this.isLoading = false;
      return;
    }
    await this.sendChatMessage(message);
    // deactivate loading
    this.isLoading = false;
  }

  // update case manager messages to read
  async updateCaseManagerMessagesToRead() {
    try {
      // update new_case_manager_message to false in case doc
      await updateDoc(
        doc(
          firestore,
          'clients',
          this.case.client_id,
          'cases',
          auth.currentUser.uid,
        ),
        {
          new_case_manager_message: false,
        },
      );
    } catch (error) {
      console.error(error);
    }
  }

  // download file from firebase storage
  async downloadFile(file: CaseFile) {
    try {
      // enable loading
      this.isLoading = true;

      getDownloadURL(ref(storage, file.path)).then((url) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = decodeURIComponent(url).split('/').pop().split('?')[0];
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    } catch (error) {
      console.error(error);
    } // disable loading
    this.isLoading = false;
  }

  // pass file to file variable on selection
  onFileChange(event: Event) {
    // clear success and error message
    this.filesSuccessMessage = '';
    this.filesErrorMessage = '';
    // check file size
    if (
      this.files.length +
        this.caseFiles.filter((x) => x.is_case_user_file == true).length +
        event.target['files'].length >
      this.maxFileCount
    ) {
      this.translate
        .get('culture-portal.idea.files.error.too-large')
        .subscribe((res) => (this.filesErrorMessage = res));
    } else {
      if (event.target['files'].length > 0) {
        Array.from(event.target['files']).forEach((file: File) => {
          // check if file was already selected
          if (
            this.files.find(
              (x: File) =>
                x.name === file.name &&
                x.size === file.size &&
                x.lastModified === file.lastModified,
            )
          ) {
            this.translate
              .get('culture-portal.idea.files.error.same-file')
              .subscribe((res) => (this.filesErrorMessage = res));
          } else {
            // check if file size does not exceed 5mb
            if (file.size > this.maxFileSize) {
              this.translate
                .get('culture-portal.idea.files.error.too-many')
                .subscribe((res) => (this.filesErrorMessage = res));
            } else {
              this.files.push(file);
            }
          }
        });
      }
    }
    this.filesDataSource = new MatTableDataSource(this.files);
  }

  // remove file from files array
  removeFile(file: File) {
    const index = this.files.indexOf(file);
    this.files.splice(index, 1);
    this.filesDataSource = new MatTableDataSource(this.files);
  }

  // upload files
  async uploadFiles(clientId: string, userId: string) {
    const referenceUrl =
      'clients/' + clientId + '/cases/' + userId + '/company';
    try {
      this.files.forEach(async (file: File) => {
        // storage reference for file
        const storageRef = ref(
          storage,
          referenceUrl + '/' + uuid() + '.' + file.name.split('.').pop(),
        );
        await uploadBytes(storageRef, file);
      });
      // show success message
      this.translate
        .get('culture-portal.idea.files.success')
        .subscribe((res) => (this.filesSuccessMessage = res));
      // clear files
      this.files = [];
    } catch (error) {
      console.error(error);
      // display error message
      this.translate
        .get('culture-portal.idea.files.error.general')
        .subscribe((res) => (this.filesErrorMessage = res));
    }
  }

  async onFilesSubmit() {
    // activate loading
    this.isLoading = true;

    // clear messages
    this.filesSuccessMessage = this.filesErrorMessage = '';

    // upload case files
    await this.uploadFiles(
      this.caseCulturePortalComponent.client.id,
      auth!.currentUser!.uid,
    );

    // deactivate loading
    this.isLoading = false;
  }
}
