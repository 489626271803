<img
  *ngIf="logoUrl"
  class="client-logo"
  height="40px"
  [src]="logoUrl"
  alt="Logo"
/>
<div class="box">
  <div class="box-content">
    <div class="header">
      <div id="navbar" class="top-nav-bar top-0 position-fixed w-100 z-3">
        <button
          mat-icon-button
          *ngIf="loggedIn"
          class="text-white non-responsive settings-button-non-reponsive"
          aria-label="Settings icon"
          [routerLink]="['/management/settings']"
          routerLinkActive="router-link-active"
        >
          <mat-icon>settings</mat-icon>
        </button>
        <div class="bg-white language-selection-button non-responsive">
          <mat-form-field class="small" appearance="fill" *mcRerender="trigger">
            <mat-label>Select language</mat-label>
            <mat-select
              [(ngModel)]="lang"
              (selectionChange)="translate.use(lang)"
            >
              <mat-option
                *ngFor="let lang of translate.getLangs()"
                [value]="lang"
              >
                {{ "languages." + lang | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <button
          mat-icon-button
          *ngIf="loggedIn"
          (click)="openLogoutConfirmationDialog()"
          class="text-white non-responsive logout-button-non-reponsive"
          aria-label="Logout icon"
        >
          <mat-icon>power_settings_new</mat-icon>
        </button>
        <div class="responsive">
          <button
            mat-icon-button
            class="text-white menu-button-reponsive"
            [matMenuTriggerFor]="menu"
            aria-label="Open menu button"
          >
            <mat-icon class="menu-icon">menu</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button
              *ngIf="loggedIn"
              mat-menu-item
              [routerLink]="['/management/settings']"
              routerLinkActive="router-link-active"
            >
              <mat-icon>settings</mat-icon>
              {{ "global.settings" | translate }}
            </button>
            <button
              *ngIf="loggedIn"
              mat-menu-item
              (click)="openLogoutConfirmationDialog()"
            >
              <mat-icon>power_settings_new</mat-icon>
              {{ "global.logout" | translate }}
            </button>
            <button mat-menu-item [matMenuTriggerFor]="language_menu">
              <mat-icon>translate</mat-icon>
              Select language
            </button>
            <mat-menu #language_menu="matMenu">
              <button
                mat-menu-item
                *ngFor="let lang of translate.getLangs()"
                (click)="translate.use(lang)"
              >
                <span [innerHTML]="'languages.' + lang | translate"></span>
              </button>
            </mat-menu>
          </mat-menu>
        </div>
      </div>
    </div>
    <div class="content-2">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
