import { StepperOrientation } from '@angular/cdk/stepper';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { ref, uploadBytes } from 'firebase/storage';
import { MyErrorStateMatcher } from 'src/app/shared/functions/errorStateMatcher';
import { environment } from 'src/environments/environment';
import { storage, functions } from 'src/firebase';
import { v4 as uuid } from 'uuid';
import { CaseCulturePortalComponent } from '../case-culture-portal/case-culture-portal.component';
import jsPDF from 'jspdf';
import { FirebaseError } from 'firebase/app';
import { httpsCallable } from 'firebase/functions';
import { generateCase } from 'src/app/shared/functions/password';

@Component({
  selector: 'app-case-culture-portal-report-case',
  templateUrl: './case-culture-portal-report-case.component.html',
  styleUrls: ['./case-culture-portal-report-case.component.scss'],
})
export class CaseCulturePortalReportCaseComponent implements OnInit {
  // form variable
  reportCaseForm: FormGroup = this.formBuilder.group({
    subject: [
      '',
      Validators.compose([Validators.required, Validators.maxLength(4096)]),
    ],
    name: [''],
    description: [
      '',
      Validators.compose([Validators.required, Validators.maxLength(4096)]),
    ],
    password: [
      '',
      Validators.compose([Validators.minLength(8), Validators.maxLength(100)]),
    ],
    email: [
      '',
      Validators.compose([Validators.email, Validators.maxLength(100)]),
    ],
  });

  // name selection in form
  nameSelection = false;

  // current loge
  logo: string;

  // error messages and success variables
  errorMessage: string = '';

  // case user credentials
  caseUserCredentials = {
    caseId: '',
    password: '',
  };

  // hide custom password at report form (default)
  hide = true;

  // error state matcher for form
  matcher = new MyErrorStateMatcher();

  // loading variable to prevent duplicated api requests
  isLoading: boolean = false;

  // files for case
  files: File[] = [];

  // file validation
  maxFileCount: number = 10;
  maxFileSize: number = 5000000;

  // file datasource for table
  filesDataSource = new MatTableDataSource();
  displayedColumns: string[] = ['file_name', 'remove'];

  // mat stepper
  @ViewChild('stepper') stepper: MatStepper;

  // mat stepper validation controls
  reCaptchaSiteKey = environment.reCaptchaSiteKey;
  captchaResolved: string;

  // orientation of stepper
  stepperOrientation: StepperOrientation = 'horizontal';

  constructor(
    private formBuilder: FormBuilder,
    public caseCulturePortalComponent: CaseCulturePortalComponent,
    private translate: TranslateService,
  ) {}

  async resolved(captchaResponse: string) {
    // declare token send function with a token parameter
    this.captchaResolved = captchaResponse;
  }

  // check device screen for design of stepper
  checkDeviceScreenSize() {
    // check device width to determine stepper orientation
    const width = window.innerWidth > 0 ? window.innerWidth : screen.width;

    if (width <= 766) {
      this.stepperOrientation = 'vertical';
    }
  }

  ngOnInit(): void {
    // determine devices screen for different layouts
    this.checkDeviceScreenSize();
  }

  // pass file to file variable on selection (with validation)
  onFileChange(event: Event) {
    // clear error message
    this.errorMessage = '';
    // check file size
    if (this.files.length + event.target['files'].length > this.maxFileCount) {
      this.translate
        .get('culture-portal.idea.files.error.too-many')
        .subscribe((res) => (this.errorMessage = res));
    } else {
      if (event.target['files'].length > 0) {
        Array.from(event.target['files']).forEach((file: File) => {
          // check if file was already selected
          if (
            this.files.find(
              (x: File) =>
                x.name === file.name &&
                x.size === file.size &&
                x.lastModified === file.lastModified,
            )
          ) {
            this.translate
              .get('culture-portal.idea.files.error.same-file')
              .subscribe((res) => (this.errorMessage = res));
          } else {
            // check if file size does not exceed 5MB
            if (file.size > this.maxFileSize) {
              this.translate
                .get('culture-portal.idea.files.error.too-large')
                .subscribe((res) => (this.errorMessage = res));
            } else {
              this.files.push(file);
            }
          }
        });
      }
    }
    this.filesDataSource = new MatTableDataSource(this.files);
  }

  // remove file from files array
  removeFile(file: File) {
    const index = this.files.indexOf(file);
    this.files.splice(index, 1);
    this.filesDataSource = new MatTableDataSource(this.files);
  }

  // upload files
  async uploadFiles(clientId: string, userId: string) {
    const referenceUrl =
      'clients/' + clientId + '/cases/' + userId + '/company';
    try {
      this.files.forEach(async (file: File) => {
        // storage reference for file
        const storageRef = ref(
          storage,
          referenceUrl + '/' + uuid() + '.' + file.name.split('.').pop(),
        );
        await uploadBytes(storageRef, file);
      });
    } catch (error) {
      // display error message
      this.translate
        .get('culture-portal.idea.files.error.general')
        .subscribe((res) => (this.errorMessage = res));
    }
  }

  async reportCase(
    password: string,
    subject: string,
    description: string,
    name: string,
    email: string,
    captchaResolved: string,
  ) {
    try {
      const reportCase = httpsCallable<
        {
          captchaResolved: string;
          clientId: string;
          subject: string;
          password: string;
          name: string;
          description: string;
          email: string;
          filesNumer: number;
        },
        {
          case: {
            caseId: string;
            password: string;
            userId: string;
          };
          error?: FirebaseError;
        }
      >(functions, 'reportCase');

      const { data } = await reportCase({
        captchaResolved: captchaResolved,
        clientId: this.caseCulturePortalComponent.client.id,
        password: password,
        subject: subject,
        name: name,
        description: description,
        email: email,
        filesNumer: this.files.length,
      });

      if (!data.case) {
        // differentiate between errors (handle following errors: account with email exists)
        throw data.error;
      }

      // retrieve result data
      this.caseUserCredentials = {
        caseId: data.case.caseId,
        password: data.case.password,
      };

      if (this.files.length > 0) {
        await this.uploadFiles(
          this.caseCulturePortalComponent.client.id,
          data.case.userId,
        );
      }

      // complete the current step
      this.stepper.selected.completed = true;

      // move to next step (success)
      this.stepper.next();
    } catch (error) {
      // display error
      this.translate
        .get('culture-portal.report.error')
        .subscribe((res) => (this.errorMessage = res));
    }
  }

  async onFormSubmit() {
    // enable loading
    this.isLoading = true;

    // clear messages
    this.errorMessage = '';

    let password = this.reportCaseForm.value.password;
    if (!password) {
      password = generateCase(8);
    }

    await this.reportCase(
      password,
      this.reportCaseForm.value.subject,
      this.reportCaseForm.value.description,
      this.reportCaseForm.value.name,
      this.reportCaseForm.value.email,
      this.captchaResolved,
    );

    // disable loading
    this.isLoading = false;
  }

  // generate credentials pdf
  downloadCredentialsAsPDF(credentials: { caseId: string; password: string }) {
    const doc = new jsPDF();
    // add credentials to pdf doc
    doc.setFont('courier');
    doc.setFontSize(11);
    doc.text(credentials.caseId, 20, 20);
    doc.text(credentials.password, 20, 26);
    // download file
    doc.save('credentials.pdf');
  }

  // scroll to top
  scrollTop() {
    window.scroll(0, 0);
  }
}
