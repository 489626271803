<div
  class="h5"
  mat-dialog-title
  [innerHTML]="'global.logout' | translate"
></div>
<div
  mat-dialog-content
  class="small"
  [innerHTML]="'logout-confirmation.title' | translate"
></div>
<div mat-dialog-actions>
  <button
    mat-button
    (click)="onNoClick()"
    [innerHTML]="'global.abort' | translate"
  ></button>
  <button
    mat-button
    [mat-dialog-close]="true"
    color="primary"
    [innerHTML]="'global.confirm' | translate"
  ></button>
</div>
