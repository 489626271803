<mat-card class="m-5" *ngIf="case">
  <mat-tab-group
    mat-stretch-tabs
    (selectedTabChange)="scrollToBottom(); activeTab = $event.index"
  >
    <!-- Overview of idea -->
    <mat-tab [label]="'global.idea' | translate">
      <div class="p-5 tab-content">
        <div
          class="h5"
          [innerHTML]="'culture-portal.idea.overview' | translate"
        ></div>
        <hr />
        <p>
          {{ "culture-portal.idea.receiver" | translate }}
          <b>{{ caseCulturePortalComponent.client.name }}</b
          ><br />
          {{ "culture-portal.idea.date" | translate }}
          <b>{{ case.created }}</b>
        </p>
        <hr />

        <!-- Subject of case -->
        <mat-form-field appearance="outline">
          <mat-label
            [innerHTML]="'culture-portal.report.report.subject'"
          ></mat-label>
          <input matInput [value]="case.subject" readonly />
        </mat-form-field>

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field appearance="outline">
              <mat-label
                [innerHTML]="
                  'culture-portal.report.report.name.question' | translate
                "
              ></mat-label>
              <mat-select
                [(ngModel)]="nameSelection"
                [ngModelOptions]="{ standalone: true }"
                disabled
              >
                <mat-option [value]="false">{{
                  "global.no" | translate
                }}</mat-option>
                <mat-option [value]="true">{{
                  "global.yes" | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6" *ngIf="case.name">
            <mat-form-field appearance="outline">
              <mat-label [innerHTML]="'global.name'"></mat-label>
              <input matInput [value]="case.name" readonly />
            </mat-form-field>
            <p
              [innerHTML]="
                'culture-portal.report.report.name.description'
                  | translate: caseCulturePortalComponent.client
              "
            ></p>
          </div>
        </div>

        <hr />

        <!-- Description of case -->
        <mat-form-field class="mt-2" appearance="outline">
          <mat-label
            [innerHTML]="'culture-portal.report.report.description' | translate"
          ></mat-label>
          <textarea
            matInput
            [value]="case.description"
            cdkTextareaAutosize
            readonly
          ></textarea>
        </mat-form-field>

        <hr />
        <p
          [innerHTML]="
            'culture-portal.report.email.disclaimer'
              | translate: caseCulturePortalComponent.client
          "
        ></p>

        <!-- E-Mail Address of reporter -->
        <mat-form-field class="mt-2" appearance="outline">
          <mat-label
            [innerHTML]="'culture-portal.report.email' | translate"
          ></mat-label>
          <input *ngIf="!case.email" matInput disabled />
          <input *ngIf="case.email" matInput [value]="case.email" readonly />
        </mat-form-field>
      </div>
    </mat-tab>

    <!-- Chat with case manager -->
    <mat-tab [label]="'culture-portal.idea.chat' | translate">
      <div class="p-5 tab-content">
        <div class="row">
          <div class="col-12">
            <div
              class="h5"
              [innerHTML]="'culture-portal.idea.chat.description' | translate"
            ></div>
          </div>
        </div>
        <hr />
        <p>
          {{ "culture-portal.idea.chat.receiver" | translate }}
          <b>{{ caseCulturePortalComponent.client.name }}</b>
        </p>
        <hr />
        <div class="chat-box w-100" #content #messages>
          <div class="row w-100 mb-3" *ngFor="let chatMessage of chatMessages">
            <div *ngIf="!chatMessage.is_case_user" class="col-10 col-md-8">
              <!-- Message of case manager-->
              <div class="chat-message from-them p-3 text-left">
                <p class="mb-1" [innerText]="chatMessage.text"></p>
                <div class="small">
                  {{ chatMessage.case_manager_name }} ({{
                    "global.case-manager" | translate
                  }}), {{ chatMessage.created_at }}<br />
                </div>
              </div>
            </div>
            <div class="col-2 col-md-4"></div>
            <div *ngIf="chatMessage.is_case_user" class="col-10 col-md-8">
              <!-- Message of case user -->
              <div class="chat-message from-me p-3 text-right">
                <p class="mb-1" [innerText]="chatMessage.text"></p>
                <div class="small" *ngIf="!case.name">
                  {{ "global.proposer" | translate }},
                  {{ chatMessage.created_at }}<br />
                </div>
                <div class="small" *ngIf="case.name">
                  {{ case.name }} ({{ "global.proposer" | translate }}),
                  {{ chatMessage.created_at }}<br />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Send chat message  -->
        <mat-form-field class="mt-2" appearance="outline">
          <mat-label
            [innerHTML]="'culture-portal.idea.chat.placeholder' | translate"
          ></mat-label>
          <textarea
            [(ngModel)]="message"
            matInput
            rows="6"
            [disabled]="isLoading"
            [maxlength]="messageMaxLength"
          ></textarea>
          <mat-hint
            align="end"
            [innerText]="message.length + '/' + messageMaxLength"
          ></mat-hint>
        </mat-form-field>
        <button
          mat-raised-button
          (click)="onChatMessageFormSubmit(message)"
          [disabled]="message.length === 0 || isLoading"
          color="primary"
          [innerHTML]="'culture-portal.idea.chat.send' | translate"
        ></button>
        <p
          *ngIf="chatErrorMessage"
          class="error mt-3 text-center pt-0"
          [innerText]="chatErrorMessage"
        ></p>
      </div>
    </mat-tab>

    <!-- Files -->
    <mat-tab [label]="'Dateien'">
      <div class="p-5 tab-content">
        <div
          class="h5"
          [innerHTML]="'culture-portal.idea.files' | translate"
        ></div>
        <hr />
        <div class="tablecontainer mat-elevation-z8">
          <table mat-table [dataSource]="dataSource">
            <!-- Display files -->
            <!-- File upload timestamp -->
            <ng-container matColumnDef="uploaded">
              <th
                mat-header-cell
                *matHeaderCellDef
                [innerHTML]="'global.date' | translate"
              ></th>
              <td mat-cell *matCellDef="let file">
                {{ file.uploaded }}
              </td>
            </ng-container>

            <!-- Download file button -->
            <ng-container matColumnDef="download">
              <th
                mat-header-cell
                *matHeaderCellDef
                [innerHTML]="'culture-portal.idea.files.download' | translate"
              ></th>
              <td mat-cell *matCellDef="let file">
                <button
                  mat-icon-button
                  (click)="downloadFile(file)"
                  color="primary"
                >
                  <mat-icon>download</mat-icon>
                </button>
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
        <div
          class="h5 mt-5"
          [innerHTML]="'culture-portal.idea.files.upload-more' | translate"
        ></div>
        <hr />
        <p
          [innerHTML]="'culture-portal.idea.files.size-disclaimer' | translate"
        ></p>
        <p
          [innerHTML]="
            'culture-portal.idea.files.privacy-disclaimer' | translate
          "
        ></p>

        <input
          class="d-none"
          (change)="onFileChange($event)"
          type="file"
          [multiple]="true"
          #fileUpload
        />
        <button
          type="button"
          class="my-3 py-1 d-block w-100"
          mat-stroked-button
          (click)="fileUpload.click()"
          color="primary"
          [innerHTML]="'culture-portal.idea.files.select-files' | translate"
        ></button>
        <table
          *ngIf="files.length > 0"
          class="my-3 w-100 mat-elevation-z8"
          mat-table
          [dataSource]="filesDataSource"
        >
          <!-- Display file -->
          <!-- File name -->
          <ng-container matColumnDef="file_name">
            <th
              mat-header-cell
              *matHeaderCellDef
              [innerHTML]="'culture-portal.idea.files.file.name' | translate"
            ></th>
            <td mat-cell *matCellDef="let file">{{ file.name }}</td>
          </ng-container>
          <!-- Remove file button -->
          <ng-container matColumnDef="remove">
            <th
              mat-header-cell
              *matHeaderCellDef
              [innerHTML]="'culture-portal.idea.files.file.remove' | translate"
            ></th>
            <td mat-cell *matCellDef="let file">
              <button
                mat-icon-button
                type="button"
                (click)="removeFile(file)"
                color="warn"
                aria-label="Remove file"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumnsFilesTable; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumnsFilesTable"
          ></tr>
        </table>
        <button
          mat-raised-button
          class="my-3 mt-4 py-1 d-block w-100"
          (click)="onFilesSubmit()"
          [disabled]="files.length === 0 || isLoading"
          color="primary"
          [innerHTML]="'culture-portal.idea.files.upload' | translate"
        ></button>
        <p
          *ngIf="filesSuccessMessage"
          class="success mt-3 text-center pt-0"
          [innerText]="filesSuccessMessage"
        ></p>
        <p
          *ngIf="filesErrorMessage"
          class="error mt-3 text-center pt-0"
          [innerText]="filesErrorMessage"
        ></p>
      </div>
    </mat-tab>
  </mat-tab-group>

  <div class="pb-4 text-left px-3">
    <button
      mat-flat-button
      [routerLink]="['client']"
      routerLinkActive="router-link-active"
      [innerHTML]="'global.back' | translate"
    ></button>
  </div>
</mat-card>
