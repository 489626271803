import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormGroupDirective,
} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseError } from 'firebase/app';
import { Unsubscribe } from 'firebase/auth';
import {
  onSnapshot,
  doc,
  DocumentReference,
  DocumentData,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { FirebaseAuthService } from 'src/app/shared/services/firebase-auth.service';
import { functions, firestore, auth } from 'src/firebase';
import { CaseManagementPortalComponent } from '../case-management-portal/case-management-portal.component';
import { CaseManager } from 'src/app/shared/models/caseManager.model';

@Component({
  selector: 'app-case-management-portal-settings',
  templateUrl: './case-management-portal-settings.component.html',
  styleUrls: ['./case-management-portal-settings.component.scss'],
})
export class CaseManagementPortalSettingsComponent
  implements OnInit, OnDestroy
{
  caseManager: CaseManager;
  docRef: DocumentReference<DocumentData, DocumentData>;

  // change password form
  changePasswordForm: FormGroup = this.formBuilder.group({
    password: [
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(100),
      ]),
    ],
    new_password: [
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(100),
      ]),
    ],
    new_password2: [
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(100),
      ]),
    ],
  });

  // loading var for spinner
  isLoading = false;

  // unsubscribe for user data subscription
  unsubscribe: Unsubscribe;

  // change password
  changePasswordSuccessMessage = '';
  changePasswordErrorMessage = '';

  // hide for password input
  hide = true;

  // angular material case manager table setup
  caseManagerTableDisplayedColumns: string[] = [
    'name',
    'email',
    'role',
    'de_activate',
  ];
  caseManagerDataSource = new MatTableDataSource();

  // possible roles
  possibleRoles = [];

  constructor(
    private router: Router,
    private firebaseAuth: FirebaseAuthService,
    private formBuilder: FormBuilder,
    public caseManagementPortalComponent: CaseManagementPortalComponent,
    public translate: TranslateService,
  ) {}

  // get data of user (subscribe)
  getUserData(clientsId: string) {
    try {
      this.unsubscribe = onSnapshot(
        doc(
          firestore,
          'clients',
          clientsId,
          'case_manager',
          auth.currentUser.uid,
        ),
        (querySnapshot) => {
          if (!querySnapshot.exists()) {
            // error while getting user data (navigate to home)
            this.router.navigate(['management/home']);
          }

          const data = querySnapshot.data();

          this.caseManager = {
            id: querySnapshot.id,
            name: data.name,
            email: data.email,
            role: data.role,
            is_active: data.is_active,
          };

          this.docRef = querySnapshot.ref;
        },
      );
    } catch (error) {
      if (this.unsubscribe) {
        this.unsubscribe();
      }
    }
    // deactivate loading
    this.isLoading = false;
  }

  extract(clientId: string) {
    // get user data
    this.getUserData(clientId);
  }

  async ngOnInit() {
    // enable loading
    this.isLoading = true;

    // wait until auth is initialized
    const user = await this.firebaseAuth.getCurrentUser();

    // check initially if user is logged in
    if (user !== null) {
      // get firebase auth
      const claims = (await auth.currentUser.getIdTokenResult()).claims;

      // redirect user to login if not logged in
      if (
        claims.role !== 'case_manager' &&
        claims.role !== 'case_manager_admin'
      ) {
        // user is not logged in => redirect to login
        this.router.navigate(['management']);
      } else {
        this.extract(claims.client as string);
      }
    } else {
      // user is not logged in
      this.router.navigate(['/management/login']);
    }
  }

  ngOnDestroy(): void {
    if (this.unsubscribe) {
      // unsubscribe from subscription
      this.unsubscribe();
    }
  }

  async updateUserPassword(
    password: string,
    newPassword: string,
    form: FormGroupDirective,
  ) {
    try {
      // firebase callable functions
      const updateUserPassword = httpsCallable<
        { password: string; newPassword: string },
        { result: string; error?: FirebaseError }
      >(functions, 'updateUserPassword');

      // validate and update password in backend
      const { data } = await updateUserPassword({
        password: password,
        newPassword: newPassword,
      });

      if (!data.result) {
        throw data.error;
      }

      // reset form
      form.resetForm({
        password: '',
        new_password: '',
        new_password2: '',
      });

      // password was successfully updated (show success message)
      this.translate
        .get('management-portal.settings.password.success')
        .subscribe((res) => (this.changePasswordSuccessMessage = res));
    } catch (error) {
      // check error type
      if (error.message === 'The password is wrong.') {
        // provided user password was wrong
        this.translate
          .get('management-portal.settings.password.current.incorrect')
          .subscribe((res) => (this.changePasswordErrorMessage = res));
      } else {
        // show general error
        this.translate
          .get('management-portal.settings.password.error')
          .subscribe((res) => (this.changePasswordErrorMessage = res));
      }
    }
    // disable loading
    this.isLoading = false;
  }

  // change password
  onFormSubmit(form: FormGroupDirective) {
    // enable loading
    this.isLoading = true;

    // reset success and error messages
    this.changePasswordSuccessMessage = '';
    this.changePasswordErrorMessage = '';

    // get values of change password form
    const formValues = this.changePasswordForm.value;

    // check if new passwords match
    if (
      formValues.new_password !== formValues.new_password2 ||
      formValues.password === formValues.new_password
    ) {
      // display error to user
      this.translate
        .get('management-portal.settings.password.new.not-same')
        .subscribe((res) => (this.changePasswordErrorMessage = res));
      // disable loading
      this.isLoading = false;
    } else {
      // update password in functions (if password is correct)
      this.updateUserPassword(
        formValues.password,
        formValues.new_password,
        form,
      );
    }
  }
}
