<div class="p-4 text-center text-dark">
  <h1 mat-dialog-title cdkFocusInitial>Kontaktieren Sie uns</h1>
  <mat-dialog-content class="py-3">
    <p class="mb-1 text-dark">Rufen Sie uns an:</p>
    <a
      href="tel:+49 30 9940550-0"
      rel="noopener noreferrer"
      class="text-dark mb-1"
    >
      +49 30 9940550-0</a
    >
    <p class="mt-4 mb-1 text-dark">Schreiben Sie uns eine E-Mail:</p>
    <a
      href="mailto:info@integribox.com"
      rel="noopener noreferrer"
      class="text-dark"
    >
      info&#64;integribox.com</a
    >
  </mat-dialog-content>
  <mat-dialog-actions>
    <button class="d-block m-auto mt-2" mat-button mat-dialog-close>
      zurück zur Website
    </button>
  </mat-dialog-actions>
</div>
