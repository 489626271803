export const formatCaseCreated = (date: Date, currentLang: string) => {
  return date.toLocaleDateString(currentLang, {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const formatChatMessageCreatedAt = (date: Date, currentLang: string) => {
  return date.toLocaleDateString(currentLang, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const formatCaseFileUploaded = (date: Date, currentLang: string) => {
  return date.toLocaleDateString(currentLang, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });
};
