<div class="cover-spin" *ngIf="isLoading"></div>
<div class="row mt-3 mx-0">
  <div class="col-12 col-lg-5 left">
    <mat-card class="d-block m-auto mt-3" *ngIf="auth.currentUser">
      <p
        class="p-0 m-0 h5"
        [innerHTML]="'management-portal.home.welcome-back'"
      ></p>
      <p
        class="h6 mt-1 mb-4"
        [innerHTML]="
          'management-portal.home.welcome-back.subtitle'
            | translate: auth.currentUser
        "
      ></p>
      <div class="tablecontainer mat-elevation-z8 mt-1">
        <table mat-table [dataSource]="caseManagerDataSource">
          <!-- Display manager -->
          <!-- Name manager -->
          <ng-container matColumnDef="name">
            <th
              mat-header-cell
              *matHeaderCellDef
              [innerHTML]="'global.name' | translate"
            ></th>
            <td mat-cell *matCellDef="let caseManager">
              {{ caseManager.name }}
            </td>
          </ng-container>

          <!-- Email of manager -->
          <ng-container matColumnDef="email">
            <th
              mat-header-cell
              *matHeaderCellDef
              [innerHTML]="'management-portal.home.email' | translate"
            ></th>
            <td mat-cell *matCellDef="let caseManager">
              {{ caseManager.email }}
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="caseManagerTableDisplayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="
              let caseManager;
              columns: caseManagerTableDisplayedColumns
            "
          ></tr>
        </table>
      </div>
    </mat-card>
  </div>
  <div class="col-12 col-lg-7 right">
    <mat-card class="mt-3">
      <div
        class="h5 mb-3"
        [innerHTML]="'management-portal.home.cases.title' | translate"
      ></div>
      <div class="row">
        <div class="col-12 col-md-8">
          <mat-form-field>
            <mat-label
              [innerHTML]="'management-portal.home.cases.filter' | translate"
            ></mat-label>
            <input
              matInput
              (keyup)="filterCases($event)"
              [placeholder]="'management-portal.home.cases.filter' | translate"
            />
          </mat-form-field>
        </div>
        <div class="col-6 col-md-3">
          <button
            mat-raised-button
            (click)="getCases()"
            color="primary"
            [innerHTML]="'management-portal.home.cases.reload' | translate"
          ></button>
        </div>
      </div>
      <div class="tablecontainer mat-elevation-z8 mt-1">
        <table mat-table [dataSource]="dataSource">
          <!-- splay case -->
          <!-- Subject of case -->
          <ng-container matColumnDef="subject">
            <th
              mat-header-cell
              *matHeaderCellDef
              [innerHTML]="'culture-portal.report.report.subject' | translate"
            ></th>
            <td mat-cell *matCellDef="let case">
              {{ case.subject }}
            </td>
          </ng-container>

          <!-- Timestamp of case -->
          <ng-container matColumnDef="created">
            <th
              mat-header-cell
              *matHeaderCellDef
              [innerHTML]="'global.date' | translate"
            ></th>
            <td mat-cell *matCellDef="let case">
              {{ case.created }}
            </td>
          </ng-container>

          <!-- View case -->
          <ng-container matColumnDef="view">
            <th
              mat-header-cell
              *matHeaderCellDef
              [innerHTML]="'management-portal.home.cases.open' | translate"
            ></th>
            <td mat-cell *matCellDef="let case">
              <button
                mat-icon-button
                aria-label="View idea"
                [routerLink]="[
                  'case',
                  {
                    caseId: case.id
                  }
                ]"
                routerLinkActive="router-link-active"
              >
                <mat-icon
                  aria-hidden="false"
                  aria-label="View idea"
                  color="primary"
                  >login</mat-icon
                >
              </button>
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            [ngClass]="{
              'font-weight-bold':
                !case.new_case_data_is_read ||
                case.new_case_user_message ||
                case.new_case_user_file
            }"
            class="font-weight-bold"
            *matRowDef="let case; columns: displayedColumns"
          ></tr>
        </table>
      </div>
    </mat-card>
  </div>
</div>
