<mat-card class="my-5 p-5">
  <mat-stepper linear [orientation]="stepperOrientation" #stepper>
    <mat-step>
      <ng-template matStepLabel
        ><div
          [innerHTML]="'culture-portal.report.title.anonymity' | translate"
        ></div
      ></ng-template>
      <hr class="mt-2" />
      <p>
        {{ "global.company" | translate }}:
        <b>{{ caseCulturePortalComponent.client.name }}</b>
      </p>
      <hr class="mt-2" />
      <!-- Anonymity text-->
      <h5
        [innerHTML]="'culture-portal.report.title.anonymity' | translate"
      ></h5>
      <p>
        <b
          [innerHTML]="'culture-portal.report.text.secure.title' | translate"
        ></b>
      </p>
      <p
        [innerHTML]="
          'culture-portal.report.text.secure.text'
            | translate: caseCulturePortalComponent.client
        "
      ></p>
      <ul>
        <li
          [innerHTML]="'culture-portal.report.text.secure.bullet.1' | translate"
        ></li>
        <li
          [innerHTML]="'culture-portal.report.text.secure.bullet.2' | translate"
        ></li>
      </ul>

      <hr />

      <h5
        [innerHTML]="'culture-portal.report.title.voluntarily' | translate"
      ></h5>
      <p
        [innerHTML]="
          'culture-portal.report.text.voluntarily.text'
            | translate: caseCulturePortalComponent.client
        "
      ></p>
      <ul>
        <li
          [innerHTML]="
            'culture-portal.report.text.voluntarily.bullet.1' | translate
          "
        ></li>
        <li
          [innerHTML]="
            'culture-portal.report.text.voluntarily.bullet.2' | translate
          "
        ></li>
      </ul>
      <p
        [innerHTML]="
          'culture-portal.report.text.withdrawal'
            | translate: caseCulturePortalComponent.client
        "
      ></p>

      <hr />
      <!-- Captcha -->
      <div class="row">
        <div class="col-12 col-md-6 pt-3">
          <div
            class="h5"
            [innerHTML]="'culture-portal.report.captcha.title' | translate"
          ></div>
          <p
            [innerHTML]="'culture-portal.report.captcha.text.1' | translate"
          ></p>
          <p
            [innerHTML]="'culture-portal.report.captcha.text.2' | translate"
          ></p>
        </div>
        <div class="col-12 col-md-6 pt-3">
          <button
            class="w-100 mt-5 button-next"
            mat-raised-button
            (click)="scrollTop()"
            matStepperNext
            color="primary"
            [innerHTML]="'global.forward' | translate"
          ></button>
        </div>
      </div>
    </mat-step>
    <mat-step [completed]="false">
      <ng-template matStepLabel
        ><div
          [innerHTML]="'culture-portal.report.report.title' | translate"
        ></div
      ></ng-template>
      <hr />
      <p>
        {{ "culture-portal.report.report.recipient" | translate }}
        <b>{{ caseCulturePortalComponent.client.name }}</b>
      </p>
      <hr />
      <form [formGroup]="reportCaseForm" (ngSubmit)="onFormSubmit()">
        <!-- Subject of case -->
        <mat-form-field class="mt-2" appearance="outline">
          <mat-label
            [innerHTML]="'culture-portal.report.report.subject' | translate"
          ></mat-label>
          <input matInput maxlength="100" formControlName="subject" />
          <mat-hint
            class="input-headline"
            align="start"
            [innerHTML]="
              'culture-portal.report.report.subject.description' | translate
            "
          ></mat-hint>
          <mat-hint
            class="input-headline"
            align="end"
            [innerText]="reportCaseForm.value.subject.length + ' / 4096'"
          ></mat-hint>
          <mat-error class="input-headline">
            <span
              *ngIf="
                !reportCaseForm.get('subject').valid &&
                reportCaseForm.get('subject').touched
              "
              [innerHTML]="'culture-portal.report.report.subject' | translate"
            ></span>
          </mat-error>
        </mat-form-field>

        <hr />

        <div class="row mt-3">
          <div class="col-12 col-md-6">
            <mat-form-field appearance="outline">
              <mat-label
                [innerHTML]="
                  'culture-portal.report.report.name.question' | translate
                "
              ></mat-label>
              <mat-select
                [(ngModel)]="nameSelection"
                [ngModelOptions]="{ standalone: true }"
              >
                <mat-option [value]="false">{{
                  "global.no" | translate
                }}</mat-option>
                <mat-option [value]="true">{{
                  "global.yes" | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6" *ngIf="nameSelection">
            <mat-form-field appearance="outline">
              <mat-label [innerHTML]="'global.name' | translate"></mat-label>
              <input matInput maxlength="100" formControlName="name" />
              <mat-hint
                class="input-headline"
                align="end"
                [innerText]="reportCaseForm.value.name.length + ' / 100'"
              ></mat-hint>
            </mat-form-field>
            <p
              [innerHTML]="
                'culture-portal.report.report.name.description'
                  | translate: caseCulturePortalComponent.client
              "
            ></p>
          </div>
        </div>
        <hr />

        <!-- Description of case -->
        <mat-form-field class="mt-2" appearance="outline">
          <mat-label
            [innerHTML]="'culture-portal.report.report.description' | translate"
          ></mat-label>
          <textarea
            matInput
            formControlName="description"
            rows="6"
            maxlength="4096"
            [errorStateMatcher]="matcher"
          ></textarea>
          <mat-hint
            class="input-headline"
            align="start"
            [innerHTML]="
              'culture-portal.report.report.description.description' | translate
            "
          >
          </mat-hint>
          <mat-hint
            class="input-headline"
            align="end"
            [innerText]="reportCaseForm.value.description.length + ' / 4096'"
          ></mat-hint>
          <mat-error class="input-headline">
            <span
              *ngIf="
                !reportCaseForm.get('description').valid &&
                reportCaseForm.get('description').touched
              "
              [innerHTML]="
                'culture-portal.report.report.description.description'
                  | translate
              "
            ></span>
          </mat-error>
        </mat-form-field>
        <hr />
        <p
          [innerHTML]="'culture-portal.report.email.disclaimer' | translate"
        ></p>

        <!-- E-Mail Address of reporter -->
        <mat-form-field class="mt-2" appearance="outline">
          <mat-label
            [innerHTML]="'culture-portal.report.email' | translate"
          ></mat-label>
          <input
            matInput
            maxlength="100"
            type="email"
            formControlName="email"
            [errorStateMatcher]="matcher"
          />
          <mat-hint
            class="input-headline"
            *ngIf="reportCaseForm.value.email !== undefined"
            align="end"
            [innerText]="reportCaseForm.value.email.length + ' / 100'"
          ></mat-hint>
          <mat-error class="input-headline">
            <span
              *ngIf="
                !reportCaseForm.get('email').valid &&
                reportCaseForm.get('email').touched
              "
              [innerHTML]="'culture-portal.report.email.error' | translate"
            ></span>
          </mat-error>
        </mat-form-field>
        <hr />
        <p
          [innerHTML]="'culture-portal.idea.files.size-disclaimer' | translate"
        ></p>
        <p
          [innerHTML]="
            'culture-portal.idea.files.privacy-disclaimer' | translate
          "
        ></p>

        <input
          class="d-none"
          (change)="onFileChange($event)"
          type="file"
          [multiple]="true"
          #fileUpload
        />
        <div class="my-3">
          <button
            type="button"
            class="mb-1 py-1"
            mat-stroked-button
            (click)="fileUpload.click()"
            color="primary"
            [innerHTML]="'culture-portal.idea.files.select-files' | translate"
          ></button>
        </div>
        <table
          *ngIf="files.length > 0"
          class="my-3"
          mat-table
          [dataSource]="filesDataSource"
        >
          <!-- Display file -->
          <!-- File name -->
          <ng-container matColumnDef="file_name">
            <th
              mat-header-cell
              *matHeaderCellDef
              [innerHTML]="'culture-portal.idea.files.file.name' | translate"
            ></th>
            <td mat-cell *matCellDef="let file">{{ file.name }}</td>
          </ng-container>
          <!-- Remove file button -->
          <ng-container matColumnDef="remove">
            <th
              mat-header-cell
              *matHeaderCellDef
              [innerHTML]="'culture-portal.idea.files.file.remove' | translate"
            ></th>
            <td mat-cell *matCellDef="let file">
              <button
                mat-icon-button
                type="button"
                (click)="removeFile(file)"
                color="warn"
                aria-label="Remove file"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <hr />
        <p
          [innerHTML]="'culture-portal.report.password.disclaimer' | translate"
        ></p>
        <!-- Password of case user account -->
        <mat-form-field appearance="outline">
          <mat-label
            [innerHTML]="'culture-portal.report.password' | translate"
          ></mat-label>
          <input
            matInput
            [type]="hide ? 'password' : 'text'"
            maxlength="100"
            formControlName="password"
          />
          <button
            type="button"
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
          >
            <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
          </button>
          <mat-hint
            class="input-headline"
            align="end"
            [innerText]="reportCaseForm.value.password.length + ' / 100'"
          ></mat-hint>
          <mat-error class="input-headline">
            <span
              *ngIf="
                !reportCaseForm.get('password').valid &&
                reportCaseForm.get('password').touched
              "
              [innerHTML]="'culture-portal.report.password.error' | translate"
            ></span>
          </mat-error>
        </mat-form-field>
        <hr />
        <re-captcha
        (resolved)="resolved($event)"
        [siteKey]="reCaptchaSiteKey"
        ></re-captcha>
        <!-- Submit button and messages -->
        <div class="row mt-4 mb-5">
          <div class="col-3">
            <button
              class="w-100 py-1"
              mat-raised-button
              (click)="scrollTop()"
              matStepperPrevious
              [innerHTML]="'global.back' | translate"
            ></button>
          </div>
          <div class="col-9">
            <button
              class="py-1"
              mat-raised-button
              type="submit"
              [disabled]="
                !reportCaseForm.valid || isLoading || !captchaResolved
              "
              color="primary"
              [innerHTML]="'culture-portal.report.send' | translate"
            ></button>
          </div>
        </div>
        <p class="error" *ngIf="errorMessage">{{ errorMessage }}</p>
      </form>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel><div>Fertig</div></ng-template>
      <div class="h5 my-3">
        {{ "culture-portal.report.thanks" | translate }}
        {{ caseCulturePortalComponent.client.name }}!
      </div>
      <hr />
      <p>
        {{ "culture-portal.report.report.recipient" | translate }}
        <b>{{ caseCulturePortalComponent.client.name }}</b>
      </p>
      <p [innerHTML]="'culture-portal.report.credentials' | translate"></p>
      <p *ngIf="errorMessage" class="error">{{ errorMessage }}</p>
      <p
        [innerHTML]="
          'culture-portal.report.communication'
            | translate: caseCulturePortalComponent.client
        "
      ></p>
      <mat-form-field appearance="outline">
        <mat-label
          [innerHTML]="'culture-portal.login.id' | translate"
        ></mat-label>
        <input
          class="credential_input"
          type="text"
          matInput
          [readonly]="true"
          [value]="caseUserCredentials.caseId"
        />
        <button
          matSuffix
          mat-icon-button
          aria-label="Copy case id"
          [cdkCopyToClipboard]="caseUserCredentials.caseId"
        >
          <mat-icon>content_copy</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label [innerHTML]="'global.password' | translate"></mat-label>
        <input
          class="credential_input"
          type="text"
          matInput
          [readonly]="true"
          [value]="caseUserCredentials.password"
        />
        <button
          matSuffix
          mat-icon-button
          aria-label="Copy password"
          [cdkCopyToClipboard]="caseUserCredentials.password"
        >
          <mat-icon>content_copy</mat-icon>
        </button>
      </mat-form-field>
      <hr />
      <button
        class="py-1 w-100"
        (click)="downloadCredentialsAsPDF(caseUserCredentials)"
        mat-raised-button
        color="primary"
        [innerHTML]="'culture-portal.report.credentials.download' | translate"
      ></button>
      <hr />
      <p [innerHTML]="'culture-portal.report.closable' | translate"></p>
      <br />
      <button
        class="w-100 py-1"
        mat-raised-button
        [routerLink]="['client']"
        routerLinkActive="router-link-active"
        [innerHTML]="'global.close' | translate"
      ></button>
    </mat-step>
  </mat-stepper>
  <div class="px-2 pb-2" *ngIf="!caseUserCredentials.caseId">
    <button
      mat-flat-button
      [routerLink]="['client']"
      routerLinkActive="router-link-active"
      [innerHTML]="'global.back' | translate"
    ></button>
  </div>
</mat-card>
