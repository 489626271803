import { Component, OnInit } from '@angular/core';
import { doc, getDoc } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import { auth, firestore, storage } from 'src/firebase';
import { Client } from '../../shared/models/client.model';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { FirebaseAuthService } from 'src/app/shared/services/firebase-auth.service';
import { LogoutConfirmationDialogComponent } from 'src/app/shared/components/logout-confirmation-dialog/logout-confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { onAuthStateChanged } from 'firebase/auth';

@Component({
  selector: 'app-case-culture-portal',
  templateUrl: './case-culture-portal.component.html',
  styleUrls: ['./case-culture-portal.component.scss'],
})
export class CaseCulturePortalComponent implements OnInit {
  // client object
  client: Client;

  logoUrl: string | null;

  languages: string[] = this.translate.getLangs();

  // selected language (de)
  lang: string = 'de';

  // logged in var for footer logout button
  isLoggedIn: boolean = false;

  // whistleblower country
  public country: string = '';

  constructor(
    private router: Router,
    private firebaseAuth: FirebaseAuthService,
    private dialog: MatDialog,
    public translate: TranslateService,
  ) {}

  // get data of client
  async getClient(subdomain: string) {
    const docRef = doc(firestore, 'clients', subdomain);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      this.router.navigate(['']);
    }

    const data = docSnap.data();

    this.client = {
      id: docSnap.id,
      name: data.name,
      name_short: data.name_short,
      default_language: data.default_language,
      is_active: data.is_active,
      text: data.text,
      languages: data.languages,
    };

    // check if client is active
    if (this.client.is_active !== true) {
      // redirect to landing page (wix)
      this.router.navigate(['']);
    }

    this.languages = this.client.languages;
    this.lang = this.client.default_language;
    this.translate.use(this.lang);
  }

  async getClientLogoUrl(clientId: string) {
    // firebase storage reference of logo
    const logoRef = ref(storage, 'clients/' + clientId + '/logo.png');
    // get the download URL
    this.logoUrl = await getDownloadURL(logoRef);

    if (!this.logoUrl) {
      this.logoUrl = null;
    }
  }

  // navigation
  navigateTo(url: string) {
    this.router.navigateByUrl(url);
  }

  async ngOnInit() {
    if (
      location.hostname === 'www.ideaboxen.com' ||
      location.hostname === 'www.integrity-box.net'
    ) {
      this.navigateTo('');
    }

    if (
      location.hostname === 'management.ideaboxen.com' ||
      location.hostname === 'management.integrity-box.net'
    ) {
      // reporting management handle routing of subdomains
      this.navigateTo('/management');
    }

    let subdomain = location.hostname.split('.')[0];

    if (location.hostname === 'localhost') {
      subdomain = 'mustermann';
    }

    let blured = false;

    addEventListener('scroll', () => {
      if (window.scrollY != 0 && !blured) {
        document
          .getElementById('navbar')
          .classList.remove('un-blur-background');
        document.getElementById('navbar').classList.add('blur-background');
        blured = true;
      } else if (window.scrollY == 0 && blured) {
        document.getElementById('navbar').classList.remove('blur-background');
        document.getElementById('navbar').classList.add('un-blur-background');
        blured = false;
      }
    });

    // check if client exists and get client data
    await this.getClient(subdomain);

    // check if user is logged in for logo (subscribe to auth state)
    onAuthStateChanged(auth, async (user) => {
      if (user && auth.currentUser) {
        // get firebase auth
        const claims = (await auth.currentUser.getIdTokenResult()).claims;

        // redirect user to case if logged in
        if (claims['role'] === 'case_user') {
          // user is logged in
          this.isLoggedIn = true;
        } else {
          // user is not logged in
          this.isLoggedIn = false;
        }
      } else {
        // user is not logged in
        this.isLoggedIn = false;
      }
    });

    // get client logo url if existing
    await this.getClientLogoUrl(this.client.id);
  }

  // logout option
  async logout() {
    try {
      // sign out with firebase auth api
      await this.firebaseAuth.signOutUser();

      // redirect user to login
      this.router.navigate(['client', 'login']);
    } catch (error) {
      console.error(error);
    }
  }

  // display logout dialog
  openLogoutConfirmationDialog() {
    const dialogRef = this.dialog.open(LogoutConfirmationDialogComponent);
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result === true) {
        this.logout();
      }
    });
  }
}
