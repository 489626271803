import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './shared/components/footer/footer.component';

// angular material design module imports
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRippleModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { LanguageMissingTranslationHandler } from './shared/data/missing-translation-handler';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CaseCulturePortalComponent } from './case-culture-portal/case-culture-portal/case-culture-portal.component';
import { CaseCulturePortalHomeComponent } from './case-culture-portal/case-culture-portal-home/case-culture-portal-home.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { CaseManagementPortalComponent } from './case-management-portal/case-management-portal/case-management-portal.component';
import { CaseManagementPortalHomeComponent } from './case-management-portal/case-management-portal-home/case-management-portal-home.component';
import { CaseManagementPortalCaseComponent } from './case-management-portal/case-management-portal-case/case-management-portal-case.component';
import { CaseManagementPortalLoginComponent } from './case-management-portal/case-management-portal-login/case-management-portal-login.component';
import { CaseManagementPortalSettingsComponent } from './case-management-portal/case-management-portal-settings/case-management-portal-settings.component';
import { LogoutConfirmationDialogComponent } from './shared/components/logout-confirmation-dialog/logout-confirmation-dialog.component';
import { CaseCulturePortalReportCaseComponent } from './case-culture-portal/case-culture-portal-report-case/case-culture-portal-report-case.component';
import { CaseCulturePortalCaseComponent } from './case-culture-portal/case-culture-portal-case/case-culture-portal-case.component';
import { CaseCulturePortalLoginComponent } from './case-culture-portal/case-culture-portal-login/case-culture-portal-login.component';
import {
  LandingPageComponent,
  ContactDialogComponent,
} from './home-page/landing-page/landing-page.component';

// required for ngx-translate
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    LogoutConfirmationDialogComponent,
    CaseCulturePortalComponent,
    CaseCulturePortalHomeComponent,
    CaseManagementPortalComponent,
    CaseManagementPortalHomeComponent,
    CaseManagementPortalLoginComponent,
    CaseManagementPortalSettingsComponent,
    CaseCulturePortalReportCaseComponent,
    CaseCulturePortalCaseComponent,
    CaseCulturePortalLoginComponent,
    CaseManagementPortalCaseComponent,
    LandingPageComponent,
    ContactDialogComponent,
  ],
  imports: [
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    ClipboardModule,
    MatIconModule,
    MatExpansionModule,
    MatTableModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatToolbarModule,
    MatStepperModule,
    MatSnackBarModule,
    MatTabsModule,
    RecaptchaModule,
    MatRippleModule,
    MatPaginatorModule,
    MatMenuModule,
    MatCheckboxModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatDividerModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: LanguageMissingTranslationHandler,
      },
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
