<mat-card class="py-4 px-5">
  <div class="py-1 px-3">
    <div
      class="h5 mt-3"
      [innerHTML]="'culture-portal.login.title' | translate"
    ></div>
    <hr />
    <p [innerHTML]="'culture-portal.login.description' | translate"></p>
    <form class="mb-3" [formGroup]="loginForm" (ngSubmit)="onFormSubmit()">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label
          [innerHTML]="'culture-portal.login.id' | translate"
        ></mat-label>
        <input
          type="text"
          matInput
          formControlName="caseId"
          [errorStateMatcher]="matcher"
        />
      </mat-form-field>
      <mat-form-field class="w-100" appearance="outline">
        <mat-label [innerHTML]="'global.password' | translate"></mat-label>
        <input
          type="password"
          matInput
          formControlName="password"
          [type]="hide ? 'password' : 'text'"
          [errorStateMatcher]="matcher"
        />
        <button
          type="button"
          mat-icon-button
          matSuffix
          (click)="hide = !hide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
        >
          <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
        </button>
      </mat-form-field>
      <button
        class="w-100 mt-1 py-1"
        mat-raised-button
        type="submit"
        [disabled]="!loginForm.valid || isLoading"
        color="primary"
        [innerHTML]="'global.login' | translate"
      ></button>
      <p
        *ngIf="errorMessage"
        class="error mt-3 text-center pt-0"
        [innerText]="errorMessage"
      ></p>
    </form>
  </div>
  <div class="px-2 pb-2">
    <button
      mat-flat-button
      [routerLink]="['client']"
      routerLinkActive="router-link-active"
      [innerHTML]="'global.back' | translate"
    ></button>
  </div>
</mat-card>
