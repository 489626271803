import { Component, NgZone, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { onAuthStateChanged, RecaptchaVerifier, User } from 'firebase/auth';
import { MyErrorStateMatcher } from 'src/app/shared/functions/errorStateMatcher';
import { FirebaseAuthService } from 'src/app/shared/services/firebase-auth.service';
import { auth } from 'src/firebase';
import { CaseManagementPortalComponent } from '../case-management-portal/case-management-portal.component';

@Component({
  selector: 'app-case-management-portal-login',
  templateUrl: './case-management-portal-login.component.html',
  styleUrls: ['./case-management-portal-login.component.scss'],
})
export class CaseManagementPortalLoginComponent implements OnInit {
  // form variable
  loginForm: FormGroup = this.formBuilder.group({
    email: ['', Validators.compose([Validators.required, Validators.email])],
    password: ['', Validators.required],
  });

  // error state matcher for form
  matcher = new MyErrorStateMatcher();

  // hide password at login form (default)
  hide = true;

  // message variables
  errorMessage = '';

  // prevent duplicated api requests
  isLoading = false;

  // recaptcha verifier
  recaptchaVerifier: RecaptchaVerifier;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public caseManagementPortalComponent: CaseManagementPortalComponent,
    private firebaseAuth: FirebaseAuthService,
    private ngZone: NgZone,
    public translate: TranslateService,
  ) {}

  async ngOnInit() {
    // wait until auth is initialized
    const user = await this.firebaseAuth.getCurrentUser();

    if (user !== null) {
      // get firebase auth claims
      const claims = (await auth.currentUser.getIdTokenResult()).claims;

      // redirect case manager to home if logged in
      if (
        claims.role === 'case_manager' ||
        claims.role === 'case_manager_admin'
      ) {
        // user is logged in => redirect to home
        this.router.navigate(['management']);
      }
    }

    // check if user is logged in (subscribe to auth state)
    onAuthStateChanged(auth, async (user: User) => {
      if (user !== null) {
        // get firebase auth claims
        const claims = (await auth.currentUser.getIdTokenResult()).claims;

        // redirect case manager to home if logged in
        if (
          claims.role === 'case_manager' ||
          claims.role === 'case_manager_admin'
        ) {
          // user is logged in => redirect to home
          this.ngZone.run(() => this.router.navigate(['management']));
        }
      }
    });

    // setup google recaptcha identity validation for multi factor authentication
    this.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      'recaptcha-container',
      {
        size: 'invisible',
        callback: () => {
          return null;
        },
      },
    );
  }

  // login case user with credentials
  async login(email: string, password: string) {
    try {
      // sign in user
      await this.firebaseAuth.signInUser(email, password);
      // validate user role for case management portal (user is not enrolled with a second factor)
      this.validateUserRole();
    } catch (error) {
      switch (error.code) {
        case 'auth/user-disabled':
          // logout user in case already logged in
          this.firebaseAuth.signOutUser();
          // display wrong password error message
          this.translate
            .get('management-portal.login.error.disabled')
            .subscribe((res) => (this.errorMessage = res));

          break;

        default:
          // logout user in case already logged in
          this.firebaseAuth.signOutUser();
          // display wrong password error message
          this.translate
            .get('management-portal.login.error.credentials')
            .subscribe((res) => (this.errorMessage = res));

          break;
      }
    }
  }

  // validate user role when login was successful
  async validateUserRole() {
    try {
      // check if authenticated user is case user
      const claims = (await auth.currentUser.getIdTokenResult()).claims;

      if (
        claims.role !== 'case_manager' &&
        claims.role !== 'case_manager_admin'
      ) {
        // user is not a case manager
        this.firebaseAuth.signOutUser();
        // display error message
        this.translate
          .get('management-portal.login.error.credentials')
          .subscribe((res) => (this.errorMessage = res));
      } else {
        // redirect case manager to home
        this.router.navigate(['management']);
      }
    } catch (error) {
      // logout user in case already logged in
      this.firebaseAuth.signOutUser();
      // display wrong password error message
      this.translate
        .get('management-portal.login.error.credentials')
        .subscribe((res) => (this.errorMessage = res));
    }
  }

  async onFormSubmit() {
    // clear error message
    this.errorMessage = '';

    // activate loading var
    this.isLoading = true;

    // retrieve form data and login user
    await this.login(this.loginForm.value.email, this.loginForm.value.password);

    // disable loading if login failed
    this.isLoading = false;
  }
}
