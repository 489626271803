import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { User, onAuthStateChanged } from 'firebase/auth';
import { MyErrorStateMatcher } from 'src/app/shared/functions/errorStateMatcher';
import { FirebaseAuthService } from 'src/app/shared/services/firebase-auth.service';
import { auth } from 'src/firebase';
import { CaseCulturePortalComponent } from '../case-culture-portal/case-culture-portal.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-case-culture-portal-login',
  templateUrl: './case-culture-portal-login.component.html',
  styleUrls: ['./case-culture-portal-login.component.scss'],
})
export class CaseCulturePortalLoginComponent implements OnInit {
  // form variable
  loginForm: FormGroup = this.formBuilder.group({
    caseId: ['', Validators.required],
    password: ['', Validators.required],
  });

  // error state matcher for form
  matcher = new MyErrorStateMatcher();

  // hide password at login form (default)
  hide = true;

  // message variables
  errorMessage: string;

  // prevent duplicated api requests
  isLoading = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public caseCulturePortalComponent: CaseCulturePortalComponent,
    private firebaseAuth: FirebaseAuthService,
    public translate: TranslateService,
  ) {}

  async ngOnInit() {
    // wait until auth is initialized
    const user = await this.firebaseAuth.getCurrentUser();

    if (user !== null) {
      // get firebase auth
      const claims = (await auth.currentUser.getIdTokenResult()).claims;

      // wait until client id has loaded
      while (this.caseCulturePortalComponent.client.id == undefined) {
        await new Promise((resolve) => setTimeout(resolve, 100));
      }

      // redirect user to case if logged in
      if (
        claims.role == 'case_user' &&
        claims.client == this.caseCulturePortalComponent.client.id
      ) {
        // user is logged in => redirect to case
        this.router.navigate(['client', 'case']);
      }
    }

    // check if user is still logged in (subscribe to auth state)
    onAuthStateChanged(auth, async (user: User) => {
      if (user !== null) {
        // get users claims
        const claims = (await auth.currentUser.getIdTokenResult()).claims;

        // redirect user to case if logged in
        if (
          claims.role === 'case_user' &&
          claims.client === this.caseCulturePortalComponent.client.id
        ) {
          // user is logged in => redirect to case
          this.router.navigate(['client', 'case']);
        }
      }
    });
  }

  // login case user with credentials
  async login(email: string, password: string) {
    try {
      // sign in user
      await this.firebaseAuth.signInUser(email, password);

      // check if user is case user
      const claims = (await auth.currentUser.getIdTokenResult()).claims;

      if (
        claims.role !== 'case_user' ||
        claims.client !== this.caseCulturePortalComponent.client.id
      ) {
        throw new Error();
      }
      // redirect case user to case
      this.router.navigate(['client', 'case']);
    } catch (error) {
      // logout user in case already logged in
      this.firebaseAuth.signOutUser();
      // display error message
      this.translate
        .get('culture-portal.login.error')
        .subscribe((res) => (this.errorMessage = res));
    }
  }

  async onFormSubmit() {
    // clear error message
    this.errorMessage = '';

    // activate loading var
    this.isLoading = true;

    // retrieve form data
    const loginCredentials = this.loginForm.value;

    // format email
    const email = loginCredentials.caseId + '@integribox.de';

    // login user
    await this.login(email, loginCredentials.password);

    // deactivate loading var if login failed
    this.isLoading = false;
  }
}
