import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CaseCulturePortalComponent } from '../case-culture-portal/case-culture-portal.component';

@Component({
  selector: 'app-case-culture-portal-home',
  templateUrl: './case-culture-portal-home.component.html',
  styleUrls: ['./case-culture-portal-home.component.scss'],
})
export class CaseCulturePortalHomeComponent {
  constructor(
    public caseCulturePortalComponent: CaseCulturePortalComponent,
    public translate: TranslateService,
  ) {
    // scroll to top
    window.scroll(0, 0);
  }
}
