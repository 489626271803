import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseError } from 'firebase/app';
import { Unsubscribe, User, onAuthStateChanged } from 'firebase/auth';
import { query, collection, onSnapshot } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { MyErrorStateMatcher } from 'src/app/shared/functions/errorStateMatcher';
import { applyFilter } from 'src/app/shared/functions/tableFiltering';
import { FirebaseAuthService } from 'src/app/shared/services/firebase-auth.service';
import { auth, functions, firestore } from 'src/firebase';
import { CaseManagementPortalComponent } from '../case-management-portal/case-management-portal.component';
import { Case } from 'src/app/shared/models/case.model';
import { formatCaseCreated } from 'src/app/shared/functions/dateFormat';
import { FirebaseTimestamp } from 'src/app/shared/models/firebaseTimestamp.model';

@Component({
  selector: 'app-case-management-portal-home',
  templateUrl: './case-management-portal-home.component.html',
  styleUrls: ['./case-management-portal-home.component.scss'],
})
export class CaseManagementPortalHomeComponent implements OnInit {
  // make auth accessible for template
  auth = auth;

  clientId: string;

  // cases array
  cases: Case[] = [];

  // case managers
  caseManager = [];

  // case manager object
  caseManagerRole: string;

  // angular material cases table setup
  displayedColumns: string[] = ['subject', 'created', 'view'];
  dataSource = new MatTableDataSource();

  // angular material case manager table setup
  caseManagerTableDisplayedColumns: string[] = ['name', 'email'];
  caseManagerDataSource = new MatTableDataSource();

  // clear form
  @ViewChild(FormGroupDirective) formDirective: FormGroupDirective;

  hide = false;

  // success and error messages for case manager creation
  addCaseManagerSuccessMessage = '';
  addCaseManagerErrorMessage = '';

  // error state matcher for form
  matcher = new MyErrorStateMatcher();

  // loading animation indicator variable
  isLoading = false;

  // unsubscribes for snapshot subscribtions
  unsubscribeCaseManagerSnapshot: Unsubscribe;

  constructor(
    public caseManagementPortalComponent: CaseManagementPortalComponent,
    private router: Router,
    private firebaseAuth: FirebaseAuthService,
    public translate: TranslateService,
    public cdr: ChangeDetectorRef,
  ) {
    this.translate.onLangChange.subscribe(() => {
      cdr.detectChanges();
    });
  }

  // get cases of client
  async getCases() {
    try {
      // enable loading
      this.isLoading = true;

      // clear success and error messages
      this.addCaseManagerSuccessMessage = this.addCaseManagerErrorMessage = '';

      const getCasesByClient = httpsCallable<
        Record<string, never>,
        { cases: Case[]; error?: FirebaseError }
      >(functions, 'getCasesByClient');

      const { data } = await getCasesByClient();

      if (!data.cases) {
        // differentiate between errors (handle following errors: account with email exists)
        throw data.error;
      }

      // clear cases
      this.cases = [];

      for (let i = 0; i < data.cases.length; i++) {
        const date = new Date(
          (data.cases[i].created as FirebaseTimestamp)._seconds * 1000,
        );
        data.cases[i].created = formatCaseCreated(
          date,
          this.translate.currentLang,
        );

        // add case to cases array
        this.cases.push(data.cases[i]);
      }

      this.dataSource = new MatTableDataSource(this.cases);

      // disable loading
      this.isLoading = false;
    } catch (error) {
      console.error(error);
    }
  }

  // get all case manager of client
  getCaseManager(clientId: string) {
    try {
      // define query
      const q = query(
        collection(firestore, 'clients', clientId, 'case_manager'),
      );
      // listen to realtime updates
      onSnapshot(q, (querySnapshot) => {
        this.caseManager = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          const manager = {
            name: data.name,
            email: data.email,
          };
          this.caseManager.push(manager);
        });
        // update clients table
        this.caseManagerDataSource = new MatTableDataSource(this.caseManager);
      });
    } catch (error) {
      console.error(error);
    }
  }

  async extract(clientId: string) {
    // enable loading
    this.isLoading = true;

    // get cases of case manager
    await this.getCases();

    // get case manager of client
    this.getCaseManager(clientId);

    window.scroll(0, 0);

    // disable loading
    this.isLoading = false;
  }

  async ngOnInit() {
    // enable loading
    this.isLoading = true;

    // wait until auth is initialized
    const user = await this.firebaseAuth.getCurrentUser();

    // check initially if user is logged in
    if (user !== null) {
      // get firebase auth
      const claims = (await auth.currentUser.getIdTokenResult()).claims;

      // redirect user to login if not logged in
      if (
        claims.role !== 'case_manager' &&
        claims.role !== 'case_manager_admin'
      ) {
        // user is not logged in => redirect to login
        this.router.navigate(['management/login']);
      } else {
        this.caseManagerRole = claims.role;
        this.clientId = claims.client as string;
        this.extract(this.clientId);
      }
    } else {
      // user is not logged in
      this.router.navigate(['management/login']);
    }

    // check if user is still logged in (subscribe to auth state)
    onAuthStateChanged(auth, async (user: User) => {
      if (user !== null) {
        // get firebase auth
        const claims = (await auth.currentUser.getIdTokenResult()).claims;

        // redirect user to case if logged in
        if (
          claims.role !== 'case_manager' &&
          claims.role !== 'case_manager_admin'
        ) {
          // user is not logged in => redirect to login
          this.router.navigate(['management', 'login']);
        }
      } else {
        // user is not logged in
        this.router.navigate(['management', 'login']);
      }
    });
  }

  // table filtering
  filterCases(event: Event) {
    this.dataSource.filter = applyFilter(event);
  }

  filterCaseManager(event: Event) {
    this.caseManagerDataSource.filter = applyFilter(event);
  }
}
