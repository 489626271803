export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyBOCacsMRHNaEBRKiDTzUt2We0yKvMkFac',
    authDomain: 'idea-box-prelive.firebaseapp.com',
    projectId: 'idea-box-prelive',
    storageBucket: 'idea-box-prelive.appspot.com',
    messagingSenderId: '481742456178',
    appId: '1:481742456178:web:89b85b933568aa4608c9eb',
  },
  appCheckReCaptchaSiteKey: '6LcJxH4oAAAAAA1Pg0E9ZTw5Q00_gRCapXiuOgek',
  reCaptchaSiteKey: '6LfmtrwpAAAAAJw4mIgQZ-D_R9DW_zxC0jkGRFaF',
};
