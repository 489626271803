import { Component } from '@angular/core';
import { langCodeToName } from './shared/data/missing-translation-handler';
import { TranslateService } from '@ngx-translate/core';
import * as AOS from 'aos';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(public translate: TranslateService) {
    // initialize translate; de and en first, alphabetically sorted afterwards
    translate.addLangs(['de', 'en-us']);
    translate.addLangs(
      [
        'fr',
        'pl',
        'it',
        'sv',
        'nl',
        'es',
        'tr',
        'bg',
        'da',
        'et',
        'fi',
        'el',
        'id',
        'lv',
        'lt',
        'nb-no',
        'pt',
        'ro',
        'sk',
        'sl',
        'cs',
        'uk',
        'hu',
      ].sort((a, b) => langCodeToName(a).localeCompare(langCodeToName(b))),
    );

    // fallback language
    translate.setDefaultLang('de');

    // default to browser language
    translate.use('de');

    AOS.init(); // Initialize AOS when the component initializes
  }

  // scroll to top at every new component
  onActivate() {
    window.scroll(0, 0);
  }
}
