<div class="firstPart">
  <div class="main h-100 w-100">
    <mat-toolbar>
      <div class="flex w-100">
        <a
          class="mr-2 text-light"
          href="https://mustermann.ideaboxen.com"
          target="_blank"
          >ZUM IDEABOX VORSCHLAGSPORTAL</a
        >
      </div>
    </mat-toolbar>
    <div class="d-table h-100 w-100">
      <div class="d-table-cell text-light text-center">
        <div class="h2 mb-1">IDEABOX VORSCHLAGSPORTAL</div>
        <p class="mt-0">
          <span style="visibility: hidden">.</span>{{ typewriterDisplay }}
        </p>
      </div>
    </div>
  </div>
</div>
<div class="secondPart p-5">
  <div class="row py-3 w-100" data-aos="fade-up" data-aos-duration="1000">
    <div class="col-12 col-md-6 px-5">
      <h1 class="mt-3 text-dark">Unsere Philosophie</h1>
      <p class="text-muted">
        Auf dem Weg in die Arbeit kommt einem Ihrer Mitarbeiter eine Idee, wie
        sich ein Prozess in Ihrem Unternehmen verbessern lässt. Doch wie kann er
        diese schnell und einfach an die richtige Stelle in Ihrem Unternehmen
        weitergeben? <br />
        Oft sind die Wege in Unternehmen lang und kompliziert. Wichtige
        Vorschläge können so verloren gehen. Dafür muss es doch eine einfachere
        Lösung geben, oder?
      </p>
      <p class="text-muted">
        Natürlich! Mit dem ideaBOX Vorschlagsportal können Ihre Mitarbeiter ihre
        Ideen schnell und einfach an die richtige Stelle in Ihrem Unternehmen
        weitergeben. So können Sie die Expertise und Innovationskraft Ihrer
        Mitarbeiter nutzen und Ihr Unternehmen stetig verbessern. Egal ob im
        Büro, in der Bahn oder im Homeoffice, mit dem ideaBOX Vorschlagsportal
        sind Ihre Mitarbeiter immer und überall in der Lage, ihre Ideen zu
        teilen.
      </p>
    </div>
    <div class="col-12 col-md-6 p-5">
      <ul>
        <li class="row">
          <mat-icon
            class="col-4 mt-3"
            aria-hidden="false"
            aria-label="Check icon"
            >check</mat-icon
          >
          <p class="col-11">
            Zentrale und revisionsichere Speicherung Ihrer Daten auf unseren
            Servern
          </p>
        </li>
        <li class="row mt-2">
          <mat-icon
            class="col-1 mt-3"
            aria-hidden="false"
            aria-label="Check icon"
            >check</mat-icon
          >
          <p class="col-11">
            100%ige Erreichbarkeit durch unser leistungsstarkes und einfach zu
            bedienendes Web Portal
          </p>
        </li>
        <li class="row mt-2">
          <mat-icon
            class="col-1 mt-3"
            aria-hidden="false"
            aria-label="Check icon"
            >check</mat-icon
          >
          <p class="col-11">
            Mobile Erfassung der Ideen durch optimierte Darstellung auf allen
            Geräten
          </p>
        </li>
        <li class="row mt-2">
          <mat-icon
            class="col-1 mt-3"
            aria-hidden="false"
            aria-label="Check icon"
            >check</mat-icon
          >
          <p class="col-11">
            Einfaches Senden von Ideen durch keine langen Installationsprozesse
            und selbstverständlich unsere Hilfe
          </p>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="thirdPart">
  <div class="main py-5 text-light">
    <h2
      class="pt-3 pb-2 text-center"
      data-aos="zoom-in"
      data-aos-duration="600"
    >
      Unsere Ambitionen
    </h2>
    <div class="row pb-3">
      <div
        class="col-12 col-md-4 mt-4 text-center"
        data-aos="fade-right"
        data-aos-duration="700"
      >
        <mat-icon aria-hidden="false" aria-label="secure icon">https</mat-icon>
        <p class="mt-3">Sicherheit</p>
      </div>
      <div
        class="col-12 col-md-4 mt-4 text-center"
        data-aos="zoom-in"
        data-aos-duration="700"
      >
        <mat-icon aria-hidden="false" aria-label="Entwicklung von Website"
          >done_outline</mat-icon
        >
        <p class="mt-3">Leichte Bedienbarkeit</p>
      </div>
      <div
        class="col-12 col-md-4 mt-4 text-center"
        data-aos="fade-left"
        data-aos-duration="700"
      >
        <mat-icon aria-hidden="false" aria-label="Mac iPhone icon"
          >phonelink</mat-icon
        >
        <p class="mt-3">Mobile Kompatibilität</p>
      </div>
    </div>
  </div>
</div>
<div class="fourthPart p-5">
  <div class="row mb-3 text-center">
    <div class="col">
      <div
        class="card mb-4 shadow-sm"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <div class="card-header">
          <h4 class="my-0 fw-normal">Kostenlose Testversion</h4>
        </div>
        <div class="card-body">
          <div class="h2 card-title pricing-card-title">
            0 € <small class="text-muted">/ mo</small>
          </div>
          <ul class="list-unstyled mt-3 mb-4">
            <li>1 Testbenutzerkonto</li>
            <li>Unbegrenzter Speicherplatz</li>
            <li>Alle Funktionen der Enterprise Version zu Testzwecken</li>
            <li>Persönliche Beratung auf Anfrage</li>
          </ul>
          <a
            href="https://mustermann.ideaboxen.com"
            target="_blank"
            class="w-100 py-1"
            mat-stroked-button
            color="primary"
          >
            Jetzt testen
          </a>
        </div>
      </div>
    </div>
    <div class="col">
      <div
        class="card mb-4 shadow-sm"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <div class="card-header">
          <h4 class="my-0 fw-normal">Enterprise</h4>
        </div>
        <div class="card-body">
          <div class="h2 card-title pricing-card-title">
            Kontaktieren Sie uns
          </div>
          <ul class="list-unstyled mt-3 mb-4">
            <li>Unbegrenzte Mitarbeiteranzahl und Speicherplatz</li>
            <li>Einfach zu bedienende Webapplikation</li>
            <li>Persönliche Beratung und Einrichtung</li>
            <li>Support per Telefon und E-Mail</li>
          </ul>
          <button
            (click)="openContactDialog()"
            class="w-100 py-1"
            mat-flat-button
            color="primary"
          >
            Jetzt kontaktieren
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
