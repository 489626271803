import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  onSnapshot,
  query,
  collection,
  orderBy,
  FirestoreError,
  addDoc,
  doc,
  updateDoc,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { COUNTRIES } from 'src/app/shared/data/countries';
import { Case } from 'src/app/shared/models/case.model';
import { FirebaseAuthService } from 'src/app/shared/services/firebase-auth.service';
import { CaseManagementPortalComponent } from '../case-management-portal/case-management-portal.component';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, firestore, functions, storage } from 'src/firebase';
import { CaseFile } from 'src/app/shared/models/caseFile.model';
import { FirebaseError } from 'firebase/app';
import {
  formatCaseCreated,
  formatCaseFileUploaded,
  formatChatMessageCreatedAt,
} from 'src/app/shared/functions/dateFormat';
import { FirebaseTimestamp } from 'src/app/shared/models/firebaseTimestamp.model';
import { getDownloadURL, ref } from 'firebase/storage';

@Component({
  selector: 'app-case-management-portal-case',
  templateUrl: './case-management-portal-case.component.html',
  styleUrls: ['./case-management-portal-case.component.scss'],
})
export class CaseManagementPortalCaseComponent implements OnInit {
  clientId: string;
  caseId: string;

  // name selection in form
  nameSelection = false;

  // countries
  countries = COUNTRIES;

  // case object
  case: Case;

  // chat message
  message = '';

  // chat messages
  chatMessages = [];

  // chat message validation
  messageMaxLength = 4096;
  chatErrorMessage = '';

  @ViewChildren('messages') messages: ElementRef;
  @ViewChild('content') content: ElementRef;

  // loading
  isLoading = false;

  // chat message loading
  isChatMessageLoading = false;

  // keep chat messages with id
  i = 0;

  // active tab
  activeTab: number;

  // case files array
  caseFiles = [];

  // angular material files table setup
  displayedColumns: string[] = ['uploaded', 'download'];
  dataSource = new MatTableDataSource();

  constructor(
    public caseManagementPortalComponent: CaseManagementPortalComponent,
    private route: ActivatedRoute,
    private firebaseAuth: FirebaseAuthService,
    private router: Router,
    public translate: TranslateService,
  ) {}

  async getCaseByClient(caseId: string) {
    try {
      const getCaseByClient = httpsCallable<
        { caseId: string },
        { case: Case; error?: FirebaseError }
      >(functions, 'getCaseByClient');

      const { data } = await getCaseByClient({
        caseId: caseId,
      });

      if (!data.case) {
        // differentiate between errors (handle following errors: account with email exists)
        throw data.error;
      }

      this.case = data.case;
      const created = new Date(
        (this.case.created as FirebaseTimestamp)._seconds * 1000,
      );
      this.case.created = formatCaseCreated(
        created,
        this.translate.currentLang,
      );

      if (this.case.name) {
        this.nameSelection = true;
      }
    } catch (error) {
      console.error(error);
    }
  }

  getChatMessages(clientId: string, caseId: string) {
    this.chatMessages = [];
    onSnapshot(
      query(
        collection(
          firestore,
          'clients',
          clientId,
          'cases',
          caseId,
          'chat_messages',
        ),
        orderBy('created_at', 'asc'),
      ),
      (querySnapshot) => {
        querySnapshot.docChanges().forEach(async (change) => {
          if (change.type === 'added') {
            const data = change.doc.data();

            // format chat message
            const reatedAt = new Date(data.created_at.seconds * 1000);
            data.created_at = formatChatMessageCreatedAt(
              reatedAt,
              this.translate.currentLang,
            );

            const chatMessage = {
              id: change.doc.id,
              name: data.name,
              is_case_user: data.is_case_user,
              text: data.text,
              created_at: data.created_at,
              case_manager_name: data.case_manager_name,
              read: data.read,
            };

            this.chatMessages.push(chatMessage);
          }
        });

        // sort messages by date
        this.chatMessages.sort((a, b) =>
          a.created_at > b.created_at
            ? 1
            : b.created_at > a.created_at
              ? -1
              : 0,
        );

        // update new case user message read to false
        this.updateCaseUserMessagesToRead();

        // scroll to bottom in chat window
        setTimeout(() => {
          this.scrollToBottom();
        }, 1);
      },
      (error: FirestoreError) => {
        console.error(error);
      },
    );
  }

  // get case files
  getCaseFiles(clientId: string, caseId: string) {
    // unsubscribe from case files snapshot
    onSnapshot(
      query(
        collection(
          firestore,
          'clients',
          clientId,
          'cases',
          caseId,
          'case_files',
        ),
        orderBy('uploaded', 'asc'),
      ),
      (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            const caseFile = change.doc.data();

            // format chat message
            const reatedAt = new Date(caseFile.uploaded.seconds * 1000);
            caseFile.uploaded = formatCaseFileUploaded(
              reatedAt,
              this.translate.currentLang,
            );

            // add file object to case files array
            this.caseFiles.push(caseFile);
          }

          // sort case files by date
          this.caseFiles.sort(
            (a: { uploaded: number }, b: { uploaded: number }) =>
              a.uploaded < b.uploaded ? 1 : b.uploaded < a.uploaded ? -1 : 0,
          );

          // update case files table data source
          this.dataSource = new MatTableDataSource(this.caseFiles);
        });
      },
      (error: FirestoreError) => {
        console.error(error);
      },
    );
  }

  // update case to read
  async updateCaseDataToRead() {
    // update new_case to false in case doc
    await updateDoc(
      doc(firestore, 'clients', this.case.client_id, 'cases', this.case.id),
      {
        new_case_data_is_read: true,
      },
    );
  }

  async extract() {
    await this.getCaseByClient(this.caseId);

    // update new case data to true
    if (!this.case.new_case_data_is_read) {
      await this.updateCaseDataToRead();
    }

    // get chat messages
    this.getChatMessages(this.clientId, this.case.id);

    // get case files
    this.getCaseFiles(this.clientId, this.case.id);

    window.scroll(0, 0);
  }

  async ngOnInit() {
    // wait until auth is initialized
    const user = await this.firebaseAuth.getCurrentUser();

    // check initially if user is logged in
    if (user !== null) {
      // get firebase auth claims
      const claims = (await auth.currentUser.getIdTokenResult()).claims;

      // redirect user to case if logged in
      if (
        claims.role !== 'case_manager' &&
        claims.role !== 'case_manager_admin'
      ) {
        // user is not logged in => redirect to login
        this.router.navigate(['management', 'login']);
      } else {
        this.clientId = claims['client'] as string;
        this.caseId = this.route.snapshot.paramMap.get('caseId');
        this.extract();
      }
    } else {
      // user is not logged in
      this.router.navigate(['management', 'login']);
    }

    // check if user is still logged in (subscribe to auth state)
    onAuthStateChanged(auth, async (user) => {
      if (user !== null) {
        // get firebase auth claims
        const claims = (await auth.currentUser.getIdTokenResult()).claims;

        // redirect user to case if logged in
        if (
          claims.role !== 'case_manager' &&
          claims.role !== 'case_manager_admin'
        ) {
          // user is not logged in => redirect to login
          this.router.navigate(['management', 'login']);
        }
      } else {
        // user is not logged in
        this.router.navigate(['management', 'login']);
      }
    });
  }

  // scroll to bottom inside chat
  scrollToBottom(): void {
    this.content.nativeElement.scrollTop =
      this.content.nativeElement.scrollHeight;
  }

  // update case user messages to read
  async updateCaseUserMessagesToRead() {
    // check if user is on chat page
    if (this.activeTab === 1) {
      // update new_case_manager_message to false in case doc
      await updateDoc(
        doc(firestore, 'clients', this.case.client_id, 'cases', this.case.id),
        {
          new_case_user_message: false,
        },
      );
    }
  }

  // update case user files to viewed
  async updateCaseUserFilesToViewed() {
    // check if user is on files page
    if (this.activeTab === 2) {
      // update new_case_manager_message to false in case doc
      await updateDoc(
        doc(firestore, 'clients', this.case.client_id, 'cases', this.case.id),
        {
          new_case_user_file: false,
        },
      );
    }
  }

  // send chat message
  async sendChatMessage(clientId: string, caseId: string, messageText: string) {
    try {
      // chat message
      await addDoc(
        collection(
          firestore,
          'clients',
          clientId,
          'cases',
          caseId,
          'chat_messages',
        ),
        {
          case_manager_name: auth.currentUser.displayName,
          is_case_user: false,
          created_at: new Date(),
          text: messageText,
        },
      );
      // clear message
      this.message = '';
    } catch (error) {
      console.error(error);
      // show error to user
      this.chatErrorMessage =
        'Fehler beim Senden der Nachricht. Bitte laden Sie die Seite neu und versuchen es erneut.';
    }
  }

  // validate chat message on submit
  async onChatMessageFormSubmit(message: string) {
    // activate loading
    this.isLoading = true;
    // remove whitespaces at beginning and end of string
    message = message.trim();
    // validate message
    if (message.length > this.messageMaxLength) {
      this.chatErrorMessage =
        'Die Nachricht darf nicht länger als 4096 Zeichen sein.';
      return;
    }
    await this.sendChatMessage(this.clientId, this.caseId, message);
    // deactivate loading
    this.isLoading = false;
  }

  // download file from firebase storage
  async downloadFile(file: CaseFile) {
    try {
      // enable loading
      this.isLoading = true;

      getDownloadURL(ref(storage, file.path)).then((url) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = decodeURIComponent(url).split('/').pop().split('?')[0];
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    } catch (error) {
      console.error(error);
    } // disable loading
    this.isLoading = false;
  }
}
