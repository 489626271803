<div class="cover-spin" *ngIf="isLoading"></div>
<mat-card class="mt-5 d-block m-auto" *ngIf="caseManager">
  <div class="h4 mb-3" [innerHTML]="'global.settings' | translate"></div>
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title
          [innerHTML]="'management-portal.settings.personal.title' | translate"
        ></mat-panel-title>
        <mat-panel-description>
          {{ "management-portal.settings.personal.subtitle" | translate }}
          <mat-icon class="non-responsive">account_circle</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-form-field appearance="outline">
        <mat-label [innerHTML]="'global.name' | translate"></mat-label>
        <input matInput [value]="caseManager.name" readonly />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label
          [innerHTML]="'management-portal.login.email' | translate"
        ></mat-label>
        <input matInput [value]="caseManager.email" readonly />
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title
          [innerHTML]="'management-portal.settings.password.title' | translate"
        ></mat-panel-title>
        <mat-panel-description>
          {{ "management-portal.settings.password.subtitle" | translate }}
          <mat-icon class="non-responsive">lock</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <form
        #changePasswordNgForm="ngForm"
        [formGroup]="changePasswordForm"
        (ngSubmit)="onFormSubmit(changePasswordNgForm)"
      >
        <!-- Old password -->
        <mat-form-field appearance="outline">
          <mat-label
            [innerHTML]="
              'management-portal.settings.password.current' | translate
            "
          ></mat-label>
          <input
            matInput
            [type]="hide ? 'password' : 'text'"
            maxlength="100"
            formControlName="password"
          />
          <button
            type="button"
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
          >
            <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
          </button>
          <mat-hint
            align="end"
            [innerText]="changePasswordForm.value.password.length + ' / 100'"
          ></mat-hint>
          <mat-error>
            <span
              *ngIf="
                !changePasswordForm.get('password').valid &&
                changePasswordForm.get('password').touched
              "
              [innerHTML]="
                'management-portal.settings.password.current.invalid'
                  | translate
              "
            ></span>
          </mat-error>
        </mat-form-field>
        <!-- New password -->
        <mat-form-field appearance="outline">
          <mat-label
            [innerHTML]="'management-portal.settings.password.new' | translate"
          ></mat-label>
          <input
            matInput
            [type]="hide ? 'password' : 'text'"
            maxlength="100"
            formControlName="new_password"
          />
          <button
            type="button"
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
          >
            <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
          </button>
          <mat-hint
            align="end"
            [innerText]="
              changePasswordForm.value.new_password.length + ' / 100'
            "
          ></mat-hint>
          <mat-error>
            <span
              *ngIf="
                !changePasswordForm.get('new_password').valid &&
                changePasswordForm.get('new_password').touched
              "
              [innerHTML]="
                'management-portal.settings.password.new.error' | translate
              "
            ></span>
          </mat-error>
        </mat-form-field>
        <!-- New password (repeated) -->
        <mat-form-field appearance="outline">
          <mat-label
            [innerHTML]="
              'management-portal.settings.password.new.repeat' | translate
            "
          ></mat-label>
          <input
            matInput
            [type]="hide ? 'password' : 'text'"
            maxlength="100"
            formControlName="new_password2"
          />
          <button
            type="button"
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
          >
            <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
          </button>
          <mat-hint
            align="end"
            [innerText]="
              changePasswordForm.value.new_password2.length + ' / 100'
            "
          ></mat-hint>
          <mat-error>
            <span
              *ngIf="
                !changePasswordForm.get('new_password2').valid &&
                changePasswordForm.get('new_password2').touched
              "
              [innerHTML]="
                'management-portal.settings.password.new.error' | translate
              "
            ></span>
          </mat-error>
        </mat-form-field>
        <!-- Submit button -->
        <button
          class="w-100 mt-2"
          type="submit"
          mat-raised-button
          color="primary"
          [disabled]="!changePasswordForm.valid || isLoading"
          [innerHTML]="'management-portal.settings.password.title' | translate"
        ></button>
        <p
          class="success mt-3"
          *ngIf="changePasswordSuccessMessage"
          [innerHTML]="changePasswordSuccessMessage"
        ></p>
        <p
          class="error mt-3"
          *ngIf="changePasswordErrorMessage"
          [innerHTML]="changePasswordErrorMessage"
        ></p>
      </form>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="mt-3">
    <button
      mat-flat-button
      [routerLink]="['/management']"
      routerLinkActive="router-link-active"
      [innerHTML]="'global.back' | translate"
    ></button>
  </div>
</mat-card>
