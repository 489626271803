import { Injectable } from '@angular/core';
import { User, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { auth } from 'src/firebase';

@Injectable({
  providedIn: 'root',
})
export class FirebaseAuthService {
  // sign in user
  signInUser(email: string, password: string) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  // sign out current user
  signOutUser() {
    return signOut(auth);
  }

  // get current logged in user (after auth is initialized)
  getCurrentUser() {
    return new Promise((resolve, reject) => {
      const unsubscribe = auth.onAuthStateChanged((user: User) => {
        unsubscribe();
        resolve(user);
      }, reject);
    });
  }
}
