<div class="row my-5 mx-0 px-0" *ngIf="caseCulturePortalComponent.client">
  <div class="col-12 col-md-4 mb-3 left">
    <mat-card
      [routerLink]="['report']"
      routerLinkActive="router-link-active"
      class="text-center d-block m-auto mb-3 py-5 px-4"
    >
      <mat-icon aria-hidden="false" aria-label="Send idea icon"
        >description</mat-icon
      >
      <p
        class="font-weight-bold"
        [innerHTML]="'culture-portal.home.report-button' | translate"
      ></p>
    </mat-card>
    <mat-card
      [routerLink]="['login']"
      routerLinkActive="router-link-active"
      class="text-center d-block m-auto mb-3 py-5 px-4"
    >
      <mat-icon aria-hidden="false" aria-label="Login Icon">login</mat-icon>
      <p
        class="font-weight-bold"
        [innerHTML]="'culture-portal.home.login-button' | translate"
      ></p>
    </mat-card>
  </div>
  <div class="col-12 col-md-8 mb-3 px-4 right">
    <mat-card>
      <h5 [innerHTML]="'culture-portal.home.text.title.1' | translate"></h5>
      <p
        [innerHTML]="
          'culture-portal.home.text.1'
            | translate: caseCulturePortalComponent.client
        "
      ></p>
      <p
        [innerHTML]="
          'culture-portal.home.text.2'
            | translate: caseCulturePortalComponent.client
        "
      ></p>
      <h5 [innerHTML]="'culture-portal.home.text.title.2' | translate"></h5>
      <p
        [innerHTML]="
          'culture-portal.home.text.3'
            | translate: caseCulturePortalComponent.client
        "
      ></p>
      <h5 [innerHTML]="'culture-portal.home.text.title.3' | translate"></h5>
      <p
        [innerHTML]="
          'culture-portal.home.text.4'
            | translate: caseCulturePortalComponent.client
        "
      ></p>
      <h5 [innerHTML]="'culture-portal.home.text.title.4' | translate"></h5>
      <p
        [innerHTML]="
          'culture-portal.home.text.5'
            | translate: caseCulturePortalComponent.client
        "
      ></p>
      <h5 [innerHTML]="'culture-portal.home.text.title.5' | translate"></h5>
      <p
        [innerHTML]="
          'culture-portal.home.text.6'
            | translate: caseCulturePortalComponent.client
        "
      ></p>
      <p
        [innerHTML]="
          'culture-portal.home.text.7'
            | translate: caseCulturePortalComponent.client
        "
      ></p>
    </mat-card>
  </div>
</div>
