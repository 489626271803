<div class="cover-spin" *ngIf="!client"></div>
<div class="box">
  <div class="box-content">
    <div class="header">
      <div id="navbar" class="top-nav-bar top-0 position-fixed w-100 z-3">
        <img
          (click)="navigateTo('client')"
          tabindex="auto"
          *ngIf="logoUrl"
          class="client-logo"
          height="42px"
          [src]="logoUrl"
          (keypress)="navigateTo('client')"
          [alt]="client.name"
        />
        <div class="bg-white language-selection-button" *ngIf="!isLoggedIn">
          <mat-form-field class="small" appearance="fill">
            <mat-label>Select language</mat-label>
            <mat-select
              [(ngModel)]="lang"
              (selectionChange)="translate.use(lang)"
            >
              <div *ngFor="let langs of languages">
                <mat-option [value]="langs">
                  {{ "languages." + langs | translate }}
                </mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
        <button
          mat-icon-button
          *ngIf="isLoggedIn"
          (click)="openLogoutConfirmationDialog()"
          class="text-white non-responsive logout-button-non-reponsive"
          aria-label="Logout icon"
        >
          <mat-icon>power_settings_new</mat-icon>
        </button>
        <div
          class="bg-white non-responsive language-selection-button"
          *ngIf="isLoggedIn"
        >
          <mat-form-field class="small" appearance="fill">
            <mat-label>Select language</mat-label>
            <mat-select
              [(ngModel)]="lang"
              (selectionChange)="translate.use(lang)"
            >
              <div *ngFor="let lang of languages">
                <mat-option [value]="lang">
                  {{ "languages." + lang | translate }}
                </mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="responsive" *ngIf="isLoggedIn">
          <button
            mat-icon-button
            class="text-white menu-button-reponsive"
            [matMenuTriggerFor]="menu"
            aria-label="Open menu button"
          >
            <mat-icon class="menu-icon">menu</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button
              *ngIf="isLoggedIn"
              mat-menu-item
              (click)="openLogoutConfirmationDialog()"
            >
              <mat-icon>power_settings_new</mat-icon>
              {{ "global.logout" | translate }}
            </button>
            <button mat-menu-item [matMenuTriggerFor]="language_menu">
              <mat-icon>translate</mat-icon>
              Select language
            </button>
            <mat-menu #language_menu="matMenu">
              <div *ngFor="let lang of languages">
                <!-- *ngIf="client.reporting_portal_several_texts.hasOwnProperty(lang.toLowerCase())" -->
                <button mat-menu-item (click)="translate.use(lang)">
                  <span>{{ "languages." + lang | translate }}</span>
                </button>
              </div>
            </mat-menu>
          </mat-menu>
        </div>
      </div>
    </div>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
