<div class="cover-spin" *ngIf="!case || isLoading"></div>
<mat-card class="mt-3 d-block mx-auto" *ngIf="case">
  <mat-tab-group
    mat-stretch-tabs
    (selectedTabChange)="
      scrollToBottom();
      activeTab = $event.index;
      updateCaseUserMessagesToRead();
      updateCaseUserFilesToViewed()
    "
  >
    <!-- Overview of case -->
    <mat-tab [label]="'management-portal.idea' | translate">
      <div class="p-5 tab-content">
        <div class="row">
          <div class="col-md-8">
            <div
              class="h5"
              [innerHTML]="'management-portal.idea.overview' | translate"
            ></div>
          </div>
        </div>
        <hr />
        <p>
          {{ "culture-portal.idea.date" | translate }}
          <b>{{ case.created }}</b
          ><br />
          {{ "culture-portal.login.id" | translate }}:
          <b>{{ case.case_id }}</b>
        </p>
        <hr />
        <!-- Subject of case -->
        <mat-form-field appearance="outline">
          <mat-label
            [innerHTML]="'culture-portal.report.report.subject' | translate"
          ></mat-label>
          <input matInput [value]="case.subject" readonly />
        </mat-form-field>

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field appearance="outline">
              <mat-label
                [innerHTML]="
                  'culture-portal.report.report.name.question' | translate
                "
              ></mat-label>
              <mat-select
                [(ngModel)]="nameSelection"
                [ngModelOptions]="{ standalone: true }"
                disabled
              >
                <mat-option [value]="false">{{
                  "global.no" | translate
                }}</mat-option>
                <mat-option [value]="true">{{
                  "global.yes" | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6" *ngIf="nameSelection">
            <mat-form-field appearance="outline">
              <mat-label [innerHTML]="'global.name' | translate"></mat-label>
              <input matInput [value]="case.name" readonly />
            </mat-form-field>
          </div>
        </div>

        <hr />

        <!-- Description of case -->
        <mat-form-field class="mt-2" appearance="outline">
          <mat-label
            [innerHTML]="'culture-portal.report.report.description' | translate"
          ></mat-label>
          <textarea
            matInput
            [value]="case.description"
            cdkTextareaAutosize
            readonly
          ></textarea>
        </mat-form-field>
      </div>
    </mat-tab>

    <!-- Chat with case manager -->
    <mat-tab [label]="'Chat'">
      <div class="p-5 tab-content">
        <div
          class="h5"
          [innerHTML]="'management-portal.idea.chat' | translate"
        ></div>

        <hr />
        <p>
          {{ "culture-portal.login.id" | translate }}:
          <b>{{ case.case_id }}</b>
        </p>
        <hr />
        <div class="chat-box w-100" #content #messages>
          <div class="row w-100 mb-3" *ngFor="let chatMessage of chatMessages">
            <div *ngIf="chatMessage.is_case_user" class="col-10 col-md-8">
              <!-- Message of case user -->
              <div class="chat-message from-them p-3 text-left">
                <p class="mb-1">{{ chatMessage.text }}</p>
                <div class="small" *ngIf="!case.name">
                  {{ "global.proposer" | translate }},
                  {{ chatMessage.created_at }}<br />
                </div>
                <div class="small" *ngIf="case.name">
                  {{ case.name }} ({{ "global.proposer" | translate }}),
                  {{ chatMessage.created_at }}<br />
                </div>
              </div>
            </div>
            <div class="col-2 col-md-4"></div>
            <div *ngIf="!chatMessage.is_case_user" class="col-10 col-md-8">
              <!-- Message of case manager -->
              <div class="chat-message from-me p-3 text-right">
                <p class="mb-1">
                  {{ chatMessage.text }}
                  <mat-icon
                    *ngIf="!chatMessage.read"
                    aria-hidden="false"
                    aria-label="Message delivered"
                    >done</mat-icon
                  ><mat-icon
                    *ngIf="chatMessage.read"
                    aria-hidden="false"
                    aria-label="Message read by proposer"
                    >done_all</mat-icon
                  >
                </p>
                <div class="small">
                  {{ chatMessage.case_manager_name }} ({{
                    "global.case-manager" | translate
                  }}), {{ chatMessage.created_at }}<br />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Write and send new chat message  -->
        <mat-form-field class="mt-2" appearance="outline">
          <mat-label
            [innerHTML]="'culture-portal.idea.chat.placeholder' | translate"
          ></mat-label>
          <textarea
            [(ngModel)]="message"
            matInput
            rows="6"
            [disabled]="isLoading"
            [maxlength]="messageMaxLength"
          ></textarea>
          <mat-hint
            align="end"
            [innerText]="message.length + '/' + messageMaxLength"
          ></mat-hint>
        </mat-form-field>

        <button
          class="py-1 w-100"
          mat-raised-button
          (click)="onChatMessageFormSubmit(message)"
          [disabled]="message.length === 0 || isLoading"
          color="primary"
          [innerHTML]="'culture-portal.idea.chat.send' | translate"
        ></button>

        <p
          *ngIf="chatErrorMessage"
          class="error mt-3 text-center pt-0"
          [innerText]="chatErrorMessage"
        ></p>
      </div>
    </mat-tab>

    <!-- Files -->
    <mat-tab [label]="'Dateien'">
      <div class="p-5 tab-content">
        <div
          class="h5"
          [innerHTML]="'management-portal.idea.files' | translate"
        ></div>
        <hr />
        <p>
          {{ "culture-portal.login.id" | translate }}:
          <b>{{ case.case_id }}</b>
          <br />
          {{ "management-portal.idea.files.visibility-warning" | translate }}
        </p>
        <hr />
        <div class="tablecontainer mat-elevation-z8 mb-3">
          <table mat-table [dataSource]="dataSource">
            <!-- Display files -->
            <!-- File upload timestamp -->
            <ng-container matColumnDef="uploaded">
              <th
                mat-header-cell
                *matHeaderCellDef
                [innerHTML]="'global.date' | translate"
              ></th>
              <td mat-cell *matCellDef="let file">
                {{ file.uploaded }}
              </td>
            </ng-container>

            <!-- Download file button -->
            <ng-container matColumnDef="download">
              <th
                mat-header-cell
                *matHeaderCellDef
                [innerHTML]="'culture-portal.idea.files.download' | translate"
              ></th>
              <td mat-cell *matCellDef="let file">
                <button
                  mat-icon-button
                  (click)="downloadFile(file)"
                  color="primary"
                >
                  <mat-icon>download</mat-icon>
                </button>
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
  <div class="px-2 pb-2">
    <button
      class="ml-5"
      mat-flat-button
      [routerLink]="['/management']"
      routerLinkActive="router-link-active"
      [innerHTML]="'global.back' | translate"
    ></button>
  </div>
</mat-card>
