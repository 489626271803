import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { onAuthStateChanged } from 'firebase/auth';
import { ref, getDownloadURL } from 'firebase/storage';
import { LogoutConfirmationDialogComponent } from 'src/app/shared/components/logout-confirmation-dialog/logout-confirmation-dialog.component';
import { FirebaseAuthService } from 'src/app/shared/services/firebase-auth.service';
import { storage, auth } from 'src/firebase';

@Component({
  selector: 'app-case-management-portal',
  templateUrl: './case-management-portal.component.html',
  styleUrls: ['./case-management-portal.component.scss'],
})
export class CaseManagementPortalComponent implements OnInit {
  lang: string;

  // rerendering language selection after language is loaded
  public trigger = 0;

  // logged in for logo
  loggedIn = false;

  // lsp logo url
  logoUrl: string;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private firebaseAuth: FirebaseAuthService,
    public translate: TranslateService,
  ) {
    // language
    this.lang = translate.currentLang;

    translate.onLangChange.subscribe(() => this.trigger++);
  }

  async getClientLogoUrl(clientId: string) {
    try {
      // firebase storage reference of logo
      const logoRef = ref(storage, 'clients/' + clientId + '/logo.png');
      // get the download URL
      this.logoUrl = await getDownloadURL(logoRef);
    } catch (error) {
      // check if error because of no file
      if (error.code === 'storage/object-not-found') {
        this.logoUrl = null;
      } else {
        console.error(error);
      }
    }
  }

  ngOnInit() {
    // management handle routing of subdomains
    if (
      location.hostname !== 'management.ideaboxen.com' &&
      location.hostname !== 'management.integrity-box.net' &&
      location.hostname !== 'localhost'
    ) {
      this.navigateTo('');
    }

    let blured = false;

    addEventListener('scroll', () => {
      if (window.scrollY != 0 && !blured) {
        document
          .getElementById('navbar')
          .classList.remove('un-blur-background');
        document.getElementById('navbar').classList.add('blur-background');
        blured = true;
      } else if (window.scrollY == 0 && blured) {
        document.getElementById('navbar').classList.remove('blur-background');
        document.getElementById('navbar').classList.add('un-blur-background');
        blured = false;
      }
    });

    // check if user is logged in for logo (subscribe to auth state)
    onAuthStateChanged(auth, async (user) => {
      if (user !== null) {
        // get firebase auth
        const claims = (await auth.currentUser.getIdTokenResult()).claims;

        // redirect user to case if logged in
        if (
          claims.role === 'case_manager' ||
          claims.role === 'case_manager_admin'
        ) {
          // user is logged in
          this.loggedIn = true;
          // get lsp logo url
          await this.getClientLogoUrl(claims.client as string);
        } else {
          // user is not logged in
          this.loggedIn = false;
          // remove lsp logo url
          this.logoUrl = null;
        }
      } else {
        // user is not logged in
        this.loggedIn = false;
        // remove lsp logo url
        this.logoUrl = null;
      }
    });
  }

  // navigation
  navigateTo(url: string) {
    this.router.navigateByUrl(url);
  }

  // logout option
  async logout() {
    // sign out with firebase auth api
    await this.firebaseAuth.signOutUser();

    // redirect user to login
    this.router.navigate(['management', 'login']);
  }

  // display logout dialog
  openLogoutConfirmationDialog() {
    const dialogRef = this.dialog.open(LogoutConfirmationDialogComponent);
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result === true) {
        this.logout();
      }
    });
  }
}
