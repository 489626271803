<div class="cover-spin" *ngIf="isLoading"></div>
<mat-card class="py-4 px-5 mt-5">
  <div class="py-1 px-3">
    <div
      class="h5 mt-3"
      [innerHTML]="'management-portal.login.title' | translate"
    ></div>
    <hr />
    <p [innerHTML]="'management-portal.login.subtitle' | translate"></p>
    <form class="mb-3" [formGroup]="loginForm" (ngSubmit)="onFormSubmit()">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label
          [innerHTML]="'management-portal.login.email' | translate"
        ></mat-label>
        <input
          type="email"
          matInput
          formControlName="email"
          [errorStateMatcher]="matcher"
        />
        <mat-error>
          <span
            *ngIf="
              !loginForm.get('email').valid && loginForm.get('email').touched
            "
            [innerHTML]="'management-portal.login.email.error' | translate"
          ></span>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="w-100" appearance="outline">
        <mat-label [innerHTML]="'global.password' | translate"></mat-label>
        <input
          type="password"
          matInput
          formControlName="password"
          [type]="hide ? 'password' : 'text'"
          [errorStateMatcher]="matcher"
        />
        <button
          type="button"
          mat-icon-button
          matSuffix
          (click)="hide = !hide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
        >
          <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
        </button>
        <mat-error>
          <span
            *ngIf="
              !loginForm.get('password').valid &&
              loginForm.get('password').touched
            "
            [innerHTML]="'management-portal.login.password.error' | translate"
          ></span>
        </mat-error>
      </mat-form-field>
      <button
        class="w-100 mt-1 py-1"
        mat-raised-button
        type="submit"
        [disabled]="!loginForm.valid || isLoading"
        color="primary"
        [innerHTML]="'global.login' | translate"
      ></button>
      <p
        *ngIf="errorMessage"
        class="error mt-3 text-center pt-0"
        [innerText]="errorMessage"
      ></p>
    </form>
    <div
      style="
        position: relative;
        z-index: 10;
        width: 100%;
        margin-top: 2em;
        margin-left: 2em;
        margin-right: auto;
      "
    >
      <div id="recaptcha-container"></div>
    </div>
  </div>
</mat-card>
