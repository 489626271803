<footer class="m-0 py-5 px-4 w-100">
  <div class="row m-0 w-100">
    <div class="col-12 col-md-5 px-5 my-3 text-center">
      <img
        class="d-block m-auto"
        width="200px"
        src="assets/images/integribox_logo_dark.png"
        alt="integriBOX Logo"
      />
      <p
        class="small mt-2"
        [innerHTML]="'footer.copyright' | translate: { year: year }"
      ></p>
    </div>
    <div class="col-12 col-md-4 my-3 small">
      <a
        href="https://www.integribox.com/datenschutz"
        [innerHTML]="'footer.privacy' | translate"
      ></a>
      <a
        href="https://www.integribox.com/impressum"
        [innerHTML]="'footer.imprint' | translate"
      ></a>
      <a
        href="https://www.integribox.com"
        target="_blank"
        [innerHTML]="'footer.integribox' | translate"
      ></a>
    </div>
  </div>
</footer>
